<!--
    V.1.0
    <p-button
    [disabled]="check()"
    [style]="{ width: '100%' }"
    [label]="'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.TITLE' | translate"
    (onClick)="initSender()"
/> -->

<!-- V.2.0 -->

<div *ngIf="isButton; else noButton">
   <p-button
      (onClick)="openSendProposePaymentAgreementModal()"
      [disabled]="!this.isButtonDisabled"
      icon="pi pi-money-bill"
      [rounded]="true"
      severity="secondary"
      tooltipPosition="left"
      [pTooltip]="'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.TITLE' | translate"
   />
</div>
<ng-template #noButton>
   <p
      class="flex flex-row gap-2 py-2 text-700 text-small align-items-center px-3 no-button w-full"
      [ngClass]="{ disabled: !this.isButtonDisabled }"
      (click)="openSendProposePaymentAgreementModal()"
   >
      <i class="pi pi-money-bill"></i> {{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_PAYMENT_AGREEMENT.TITLE' | translate }}
   </p>
</ng-template>
