export const locale = {
  lang: 'es',
  EVENING: 'NOCHE',
  FULL_DAY: 'TODO EL DÍA',
  MORNING: 'MAÑANA',
  beforeDueDate: 'ANTES',
  afterDueDate: 'DESPUÉS',
  REMINDER_OR_LINK: 'Recordatorio o link directo ',
  GLOBAL: {
    NO: ' No',
    YES: ' Sí',
    DELETED: ' Eliminar',
  },
  MENU: {
    PANEL: 'Panel',
    CUSTOMER_JOURNEY: 'Customer Journey',
    BEHAVIORAL_PILL: 'Píldora Conductual',
    OPERATIVA: 'Operativa',
    CLIENTS: 'Clientes',
    INSTANCES: 'Expedientes',
    OPERATIONS: 'Operaciones',
    PAYMENT_AGREEMENTS: 'Acuerdos de Pago',
    CAMPAIGNS_MANAGER: 'Gestor de Campañas',
    CAMPAIGNS: 'Campañas',
    PORTFOLIOS: 'Carteras',
    TEMPLATES: 'Plantillas',
    SEGMENTS: 'Segmentos',
    ADMINISTRATION: 'Administración',
    CONFIGURATION: 'Configuración',
    COLLECTOR_PROFILES: 'Perfiles de Cobro',
    BRANDS: 'Marcas',
    NOTIFICATION_CHANNELS: 'Canales de Notificación',
    FEEDBACK_OPTIONS: 'Opciones de Feedback',
    PAYMENT_METHODS: 'Métodos de Pago',
    USERS: 'Usuarios',
    TAX_ENTITIES: 'Entidades Fiscales',
    SERVICES: 'Servicios',
    REGIONAL_CONFIGURATION: 'Configuración Regional',
    DATA_SHARING: 'Compartir Datos',
    PHASES: 'Fases',
  },
  DATA: {
    REPORTS: 'REPORTES',
    CHANNEL: {
      EMAIL: 'Email',
      PHONE: 'Teléfono',
      SMS: 'SMS',
      CERTIFIED_EMAIL: 'SMS certificado',
      CERTIFIED_SMS: 'Email certificado',
    },
    COMMON: {
      GENDER: {
        MALE: '♂ Hombre',
        FEMALE: '♀ Mujer',
        NOT_KNOWN: 'Prefiero no decirlo',
        NON_BINARY: 'No binario',
      },
      CHANNEL: {
        EMAIL: 'Email',
        PHONE: 'Teléfono',
      },
      COUNTRIES: {
        ES: 'Español',
        ES_MX: 'Español (México)',
        EN: 'Inglés',
        CA: 'Catalán',
        CA_ES_VALENCIA: 'Valenciano',
        FR: 'Francés',
        NL: 'Neerlandés',
        PT: 'Portugués',
        DE: 'Alemán',
        MX: 'Español (México)',
        GB: 'Inglés',
        CAT: 'Catalán',
        IT: 'Italiano',
      },
      PAGINATOR: '{first} - {last} de {totalRecords} resultados',
      PROFILE: {
        HELLO: 'Hola,',
      },
      WORKFLOW_TYPE: {
        PILL: 'Píldora',
        DRIP: 'Continua',
      },
      SECTOR: {
        ENERGIA: 'Energía',
        FINANZAS: 'Finanzas',
        SEGUROS: 'Seguros',
        TELECOMUNICACIONES: 'Telecomunicaciones',
        GIMNASIOS: 'Gimnasios',
        TRASTEROS: 'Trasteros',
        UTILITIES: 'Utilities',
      },
      TREATMENT: {
        TU: 'Tú',
        USTED: 'Usted',
      },
      TONE: {
        BAJO: 'Baja',
        MEDIO: 'Medio',
        ALTO: 'Alto',
      },
      TARGET: {
        AMISTOSO: 'Amistoso',
        RECORDATORIO: 'Recordatorio',
        AVISO_SUSPENSION_SERVICIOS: 'Aviso suspensión de servicios',
        CONFIRMACION_SUSPENSION_SERVICIOS: 'Confirmación suspensión de servicios',
        AVISO_BAJA_SERVICIO: 'Aviso baja definitiva del servicio',
        CONFIRMACION_BAJA_SERVICIO: 'Confirmación baja definitiva del servicio',
        AVISO_FIN_CONTRATO: 'Aviso finalización de contrato',
        CONFIRMACION_FIN_CONTRATO: 'Confirmación finalización de contrato',
        AVISO_INCLUSION_BBDD:
          'Aviso de inclusión en las bbdd públicas de deudores (asnef/badexcug)',
        AVISO_INCIDENCIAS_LEGALES: 'Aviso posibles incidencias legales',
        CONFIRMACION_INCIDENCIAS_LEGALES: 'Confirmación incidencias legales',
      },
      PLACEHOLDER: {
        short_name: '%nombre corto%',
        long_name: '%nombre largo%',
        tax_entity_society: '%nombre entidad fiscal%',
        tax_entity_vat_number: '%cif entidad fiscal%',
        case_amount: '%importe caso%',
        send_date: '%fecha de envío%',
        brand: '%marca%',
        pending_operations: '%operaciones pendientes%',
      },
      HUSO: {
        LABEL: 'Huso horario',
        BUTTON_TEXT: 'Asigna huso horario',
        HEADER: 'Seleccionar huso horario',
        TOOLTIP:
          'Ajusta la zona horaria para ver las horas exactas de pagos en otros países. Por ejemplo, si estás en España, puedes ver a qué hora se realizaron pagos en México o Perú.',
      },
      GENERIC: {
        NOT_FOUND: 'No encontrado',
        IS: 'es',
        MORE: 'más',
        RESET_FILTERS: 'Limpiars filtros',
      },
      NAMES: {
        CAMPAIGN: 'campaña',
        PILL: 'píldora',
        COLLECTOR: 'perfil de cobro',
      },
      OWNER: {
        BUSINESS: 'Empresa',
        PRIVATE: 'Privado',
      },
      ACTIONS: {
        PUBLISH: 'Publicar ',
        DRAFT: 'Archivar ',
        PAUSED: 'Pausar ',
        SAVE: 'Guardar',
        DELETE: 'Eliminar',
        DRAFT_INFO: 'No hay cambios de estado disponibles',
        CANCEL: 'Cancelar',
        SEND: 'Enviar',
        DOWNLOAD: 'Descargar',
        CLONE: 'Clonar',
        SEARCH: 'Buscar',
        FILTERS: 'Filtros',
        SELECT_PLACEHOLDER: 'Selecciona {{placeholder}}',
        APPLY_FILTERS: 'Aplicar filtros',
        QUESTION: '¿Quieres ejecutar esta acción?',
      },
      USER_MENU: {
        PROFILE: 'Perfil',
        LANGUAGE: 'Lenguaje',
        LOG_OUT: 'Cerrar sesión',
      },
      SEARCH: {
        SEARCH_BUTTON: 'Buscar',
        RESET_BUTTON: 'Limpiar',
        PLACEHOLDER_DROPDOWN: 'Selecciona una opción',
      },
      FORM_BUTTONS: {
        SAVE: 'Guardar',
        CANCEL: 'Cancelar',
        SEND: 'Enviar',
        CONFIRM: 'Confirmar',
        EDIT: 'Editar',
        CLONE: 'Clonar',
      },
      TOAST: {
        SUCCESS: 'Èxito',
        ERROR: 'Error',
        SUCCESS_EMAIL: 'Recordatorio enviado correctamente a {{email}}',
        SUCCESS_SMS: 'Recordatorio enviado correctamente a {{sms}}',
        NO_NOTIFICATED: 'No se envío la notificación',
        CONFIRMED: 'Confirmado!',
        CONFIRMED_EXECUTION: 'Acción ejecutada correctamente',
        SCENARIOS: {
          LIST: {
            ERROR: 'Error al listar los elementos',
          },
        },
        COPIED: 'Copiado!',
        COPIED_TEXT: 'Texto {{text}} copiado correctamente',
        COPY_ID: 'Copía id',
      },
      TIME: {
        TODAY: 'Hoy',
        YESTERDAY: 'Ayer',
        LAST_WEEK: 'Última semana',
        LAST_MONTH: 'Último mes',
        LAST_YEAR: 'Último año',
        DAYS: 'días',
        DAY: 'día',
        MONTH: 'mes',
        MONTHS: 'meses',
      },
      GENDERS: {
        MALE: 'Hombre',
        FEMALE: 'Mujer',
        NOT_KNOW: 'Prefiero no decirlo',
        NOT_BINARY: 'No binario',
      },
      MONTHS: {
        JANUARY: 'Enero',
        FEBRUARY: 'Febrero',
        MARCH: 'Marzo',
        APRIL: 'Abril',
        MAY: 'Mayo',
        JUNE: 'Junio',
        JULY: 'Julio',
        AUGUST: 'Agosto',
        SEPTEMBER: 'Septiembre',
        OCTOBER: 'Octubre',
        NOVEMBER: 'Noviembre',
        DECEMBER: 'Diciembre',
      },
      LANGUAGES: {
        ES: 'Español',
        EN: 'Inglés',
        CA: 'Catalán',
        FR: 'Francés',
        CV: 'Valenciano',
      },
      PAYMENT_METHODS: {
        CASH: 'Efectivo',
        BANK_TRANSFER: 'Transferencia bancaria',
        OTHER: 'Otro',
        DIRECT_DEBIT: 'Domiciliación bancaria',
        CREDIT_CARD: 'Tarjeta de crédito',
        PAYMEFY: 'Paymefy',
        UNKNOWN: 'Método Desconocido',
      },
      STATUS: {
        on_hold: 'En espera',
        pending: 'Pendiente',
        in_allegations: 'Alegaciones',
        charged: 'Cobrado',
        agreement: 'Acuerdo de pago',
        discarded: 'Descartado',
        opened: 'Abierto',
        open: 'Abierto',
        closed: 'Cerrado',
        created: 'Creado',
        close: 'Cerrado',
        worked: 'Trabajado',
        silenced: 'Silenciado',
        queued: 'En cola',
        generating: 'Generando',
        generated: 'Generado',
        failed: 'Fallo',
        archived: 'Archivado',
        draft: 'Borrador',
        committed: 'Carga finalizada',
        processing: 'Procesando',
        committing: 'Cargando',
        invalid_file: 'Archivo inválido',
        valid: 'Válido',
        not_valid: 'No válido',
        paused: 'Pausado',
        published: 'Publicado',
        unpublished: 'Archivado',
        running: 'Activo',
        expired: 'Expirado',
        proposal: 'Propuesta',
        signed: 'Firmado',
        fulfilled: 'Completado',
        broken: 'Roto',
        started: 'Iniciado',
        notifying: 'Notificando',
        blacklisted: 'Lista negra',
      },
      TIME_OF_WEEK: {
        FULL_DAY: 'todo el día',
        EVENING: 'la tarde',
        MORNING: 'la mañana',
      },
      CHARACTERS: 'Carácteres',
      FORM: {
        PLACEHOLDER_RANGE: 'Fecha inicio - Fecha fin',
        ERROR: {
          MIN_LENGTH: 'La longitud mínima requerida es de {{requiredLength}} caracteres.',
          MAX_LENGTH: 'La longitud máxima permitida es de {{requiredLength}} caracteres.',
          MIN: 'El valor mínimo permitido es {{min}}.',
          MAX: 'El valor máximo permitido es {{max}}.',
          MAX_CHAR: 'Se ha excedido el límite máximo de {{maxChar}} caracteres.',
          MIN_ELEMENTS_IN_ARRAY: 'Se requieren al menos {{amount}} elementos.',
          REQUIRED: 'Este campo es obligatorio.',
          EMAIL: 'Introduce una dirección de correo electrónico válida.',
          PATTERN: 'Formato no válido.',
          EMPTY_VALUE: 'Este campo no puede estar vacío.',
          INVALID_DNI_NIF: 'Formato de DNI o NIF no válido.',
          INVALID_CP: 'Formato de código postal no válido.',
          MAX_SAFE_INTEGER: 'Se ha excedido el número máximo aceptado.',
          INVALID_ALPHA_DIGIT: 'Formato de alias no válido.',
          IBAN: 'Formato de IBAN no válido.',
          BIC: 'Formato de BIC no válido.',
          FUTURE_DATE: 'La fecha debe estar en el futuro.',
          MAX_IMPORT_ON_TOTAL_AMOUNT: 'El importe no puede exceder el total.',
          STARTS_WITH_AND_ENDS_WITH: 'El campo debe empezar y terminar con {{char}}.',
          RANGE_INVALID: 'El campo final no puede ser mayor que el campo inicial.',
          RANGE_NOT_COMPLETED: 'Los campos de inicio y fin deben estar ambos completos o vacíos.',
          ALL_OPTIONS_SELECTED: 'No se pueden seleccionar todas las opciones.',
          SELECT_RANGE_DATES: 'Selecciona un rango de fechas correcto',
          PHONE_CODE: 'El número de teléfono no es válido, ejemplo: +34000000000',
          HTTP: 'La URL debe comenzar con http:// o https://',
          URL_NOT_VALID: 'La URL no es válida, ejemplo: https://example.com',
          ALIAS: 'El ALIAS contiene caracteres no permitidos',
        },
      },
    },
    WEEK_DAYS: {
      MONDAY: 'Lunes',
      TUESDAY: 'Martes',
      WEDNESDAY: 'Miércoles',
      THURSDAY: 'Jueves',
      FRIDAY: 'Viernes',
      SATURDAY: 'Sábado',
      SUNDAY: 'Domingo',
    },
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    MENU: {
      SIDE: {
        DASHBOARD: 'panel',
        ANALYTICS: 'Analytics',
        CRM: {
          CRM: 'CRM',
          IMPORT_LINES: 'Listado de importaciones',
          EXPEDIENTS: 'Operaciones',
          CUSTOMERS: 'Clientes',
          PAYMENT_AGREEMENT: 'Acuerdos de pago',
          SEGMENTS: 'Segmentos',
          PORTFOLIOS: 'Carteras',
        },
        CAMPAIGN_MANAGER: {
          CAMPAIGN_MANAGER: 'Campaign Manager',
          CAMPAIGNS: 'Campañas',
          COLLECTION_CYCLE: 'Ciclos de cobro',
          WORKFLOW: 'Flujo automático',
          TEMPLATES: 'Plantillas',
        },
        ADMINISTRATION: {
          ADMINISTRATION: 'Administración',
          INVOICES: 'Facturas',
          USERS: 'Usuarios',
          COLLECTORS: 'Collectors',
          REGIONAL_CONFIG: 'Configuración regional',
          PAYMENT_SOLUTION: 'Soluciones de pago',
          BRANDS: 'Marcas',
          TAX_ENTITIES: 'Entidades fiscales',
          PAYMENT_METHODS: 'Métodos de pago',
          NOTIFICATION_CHANNELS: 'Canales de notificación',
          FEEDBACK_OPTIONS: 'Opciones de Feedback',
          SERVICES: 'Servicios',
          EVENT_HOOK: 'Métodos de seguimiento',
        },
      },
    },
    ADMIN: {
      TITLE: 'Administración',
      MODULES: {
        COPIES: {
          CHANNEL: 'Canal',
          LANGUAGE: 'Idioma',
          SEARCH: 'Búsqueda',
          GENERATE_IA: 'Generar con IA',
          WANT_DELETE: '¿Quieres eliminar {{name}}?',
          TEMPLATE_DELETE: 'Eliminar plantilla',
          TEMPLATE_DELETED: 'Plantilla elminada correctamente',
          CLONED: 'Plantilla clonada con éxito',
          CLONED_ERROR: 'No se pudo clonar la plantilla',
          CLONED_NAME: 'Añade un nombre para la nueva plantilla:',
          CLONED_HEADER: 'Clonar la plantilla',
          CLONED_NAME_NEW: 'Nombre:',
          NEW_TEMPLATE: 'Nueva plantilla',
          ALL_CHANNELS: 'Todos los canales',
          SMS: 'SMS',
          SAVE_CHANGES: 'Guardar cambios',
          EMAIL: 'Correo electrónico',
          CERTIFIED_SMS: 'SMS Certificado',
          CERTIFIED_EMAIL: 'Correo Certificado',
          NAME: 'Nombre',
          VERSION: 'Versión',
          LAST_UPDATE: 'Última actualización',
          REMINDER: 'Recordatorio',
          PAYMENT_AGREEMENT: 'Acuerdo de pago',
          ACTIONS: 'Acciones',
          NO_TEMPLATES: 'No hay plantillas para mostrar',
          CREATE_TEMPLATE: 'Crear plantilla',
          AUTOCOMPLETE_GPT: 'Autocompletar con Chat GPT',
          TOOLTIP_MUST_FIELDS: 'Por favor, completa los campos obligatórios',
          GENERATING_TEMPLATE: 'Generando plantilla...',
          CLEAN: 'Limpiar',
          INFO_SMS: 'Info SMS',
          TOOLTIP_ICON:
            'Recuerda que la longitud máxima de un SMS es de <b>160 caracteres</b>, mientras que los paylinks ocupan aproximadamente 35 caracteres en la posición final del texto. Para evitar duplicar el costo del SMS, es importante mantener el texto por debajo de los <b>125 caracteres</b>.',
          TOOLTIP_RECOMMENDATION:
            'Para controlar mejor los gastos de SMS, te recomendamos <b>usar los placeholders de manera limitada</b> en este canal. El contenido del mensaje puede variar, lo que podría resultar en el envío de dos SMS en lugar de uno si se supera el límite de caracteres.',
          SMS_CERTIFIED_INFO: {
            ICON: "Para configurar tus notificaciones correctamente, es esencial definir el tipo de SMS certificado que deseas enviar. Ten en cuenta que a cada tipo de SMS se le agregará la mención al final del SMS: '(SMS CERTIFICADO)' y el paylink.",
            COUNTER_INFO: 'Nuestro contador ya descuenta estos caracteres.',
            LENGTH_AND_COST:
              'En cuanto a la longitud y el control de costos, ofrecemos los siguientes tipos de SMS certificados:',
            TYPES: {
              MINI: 'Mini (108 caracteres)',
              STANDARD: 'Estándar (560 caracteres)',
              TWO_SMS: '2 SMS Certificados (1,784 caracteres)',
              THREE_SMS: '3 SMS Certificados (2,396 caracteres)',
            },
            SELECTION_INSTRUCTIONS:
              'Selecciona la longitud deseada y completa el campo de texto con el contenido y los placeholders que desees utilizar. Recuerda que el número de caracteres afecta el costo y la efectividad de tu comunicación.',
            RECOMMENDATION:
              'Elige el tipo de SMS certificado que mejor se adapte a tus necesidades de mensaje y presupuesto.',
          },
          FORM: {
            SECTOR: 'Sector',
            CASE: 'Asunto',
            TEXT: 'Texto',
            TEXT_BUTTON: 'Texto del botón',
            CLIENT_TREATMENT: 'Tratamiento al cliente',
            GOAL_MSG: 'Objetivo del mensaje',
            INTENSITY_MSG: 'Intensidad del mensaje',
            VARIABLES: 'Variables',
            OP_ADVANCED: 'Operaciones avanzadas',
            NUMB_CHARACTERS: 'Número de caracteres',
            NAME: 'Nombre',
            PRE_HEADER: 'Pre encabezado',
            LANGUAGE: 'Idiomas',
            CHANNEL: 'Canal',
            ALIAS: 'Alias',
            TEMPLATE_MAIL: 'ID de plantilla de correo',
            TOOLTIP_PRE: 'Máximo 125 caracteres',
            TOOLTIP_ALIAS:
              'El campo ALIAS es opcional y se utiliza para asignar un identificador único a una plantilla de cualquier canal.\n' +
              '\n' +
              'Los caracteres que se admiten son:\n' +
              'a b c d e f g h i j k l m n o p q r s t u v w x y z A B C D E F G H I J K L M N O P Q R S T U V W X Y Z 0 1 2 3 4 5 6 7 8 9 - _ . ~.\n' +
              '\n' +
              'No se permiten espacios',
          },
        },
        BRAND: {
          TITLE: 'Marcas',
          PAGES: {
            ADD: {
              TITLE: 'Crear marca',
            },
            EDIT: {
              TITLE: 'Editar marca',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                LOGO: 'LOGO',
                NAME: 'NOMBRE',
                COLLECTORS: 'PERFILES DE COBRO',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay marcas que mostrar',
              },
            },
          },
        },
        USERS: {
          TITLE: 'Usuarios',
          PAGES: {
            ADD: {
              TITLE: 'Crear usuario',
            },
            EDIT: {
              TITLE: 'Editar usuario',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                SURNAME: 'APELLIDOS',
                ROLE: 'ROL',
                EMAIL: 'EMAIL',
                COLLECTORS: 'PERFILES DE COBRO',
                ACTIONS: 'ACCIONES',
                LANGUAGE: 'LENGUAJE',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay usuarios que mostrar',
              },
            },
          },
        },
        PORTFOLIOS: {
          TITLE: 'Carteras',
          FORM: {
            NAME: 'Nombre',
            ALIAS: 'Alias',
            SEARCH: 'Búsqueda',
          },
          MESSAGE: {
            PORTFOLIO_CREATED: 'Cartera creada correctamente',
            PORTFOLIO_UPDATED: 'Cartera actualizada correctamente',
            PORTFOLIO_DELETED: 'Cartera eliminada correctamente',
            SUCCESS: 'Éxito',
            WANT_DELETE: '¿Quieres eliminar {{name}} ?',
            DELETE_PORTFOLIO: 'Eliminar cartera',
            YES: 'Eliminar',
            NO: 'No',
          },
          PAGES: {
            ADD: {
              TITLE: 'Crear cartera',
            },
            EDIT: {
              TITLE: 'Editar cartera',
            },
          },
          STATUS: {
            PENDIENTE: 'Pendiente',
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                ALIAS: 'ALIAS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                CREATE: 'Crear cartera',
              },
              MORE: {
                NO_RESULTS: 'No hay carteras que mostrar',
              },
            },
          },
        },
        TAX_ENTITY: {
          TITLE: 'Entidades fiscales',
          FORM: {
            NAME: 'Nombre',
            ALIAS: 'Alias',
          },
          PAGES: {
            ADD: {
              TITLE: 'Crear Entidades fiscal',
            },
            EDIT: {
              TITLE: 'Editar Entidades fiscal',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                SOCIETY: 'RAZÓN SOCIAL',
                NIF: 'NIF',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay Entidades fiscales que mostrar',
              },
            },
          },
        },
        SERVICE: {
          TITLE: 'Servicos',
          CATEGORY: {
            advertising: 'Publicidad',
            energy: 'Energía',
            financial_services: 'Servicios financieros',
            insurance: 'Seguros',
            lawyers: 'Abogados',
            others: 'Otros',
            real_estate_services: 'Servicios inmobiliarios',
            telecommunications: 'Telecomunicaciones',
            training: 'Formación',
          },
          PAGES: {
            ADD: {
              TITLE: 'Crear Servico',
            },
            EDIT: {
              TITLE: 'Editar Servico',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                CATEGORY: 'CATEGORÍA',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay Servicos que mostrar',
              },
            },
          },
        },
        PARTIAL_PAYMENT_CONFIGS: {
          TITLE: 'Soluciones de pago',
          PAGES: {
            ADD: {
              TITLE: 'Crear configuración regional',
            },
            EDIT: {
              TITLE: 'Editar configuración regional',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_COLLECTORS: 'No hay perfiles de cobro asociados',
                NO_RESULTS: 'No hay Configuración de pagos parciales que mostrar',
              },
            },
          },
        },
        EVENT_HOOKS: {
          TITLE: 'Compartir Datos',
          PAGES: {
            ADD: {
              TITLE: 'Crear método de seguimiento',
            },
            EDIT: {
              TITLE: 'Editar método de seguimiento',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                TYPE: 'TIPO',
                URL: 'SEGUIMIENTO',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
                CONTEXT: 'CONTEXTOS',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_COLLECTORS: 'No hay perfiles de cobro asociados',
                NO_RESULTS: 'No hay métodos de seguimiento que mostrar',
              },
            },
          },
        },
        REGIONAL_CONFIGS: {
          TITLE: 'Configuración regional',
          PAGES: {
            ADD: {
              TITLE: 'Crear',
            },
            EDIT: {
              TITLE: 'Editar',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                CREATE: 'Configuracion regional',
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_COLLECTORS: 'No hay perfiles de cobro asociados',
                NO_RESULTS: 'No hay configuraciones regionales que mostrar',
              },
            },
          },
        },
        PAYMENT_METHODS: {
          TITLE: 'Métodos de pago',
          TYPES: {
            RedsysPaymentGateway: 'Redsys',
            PaycometPaymentGateway: 'Paycomet',
            IngenicoPaymentGateway: 'Ingenico',
            MymoidPaymentGateway: 'MyMoid',
            MoneticoPaymentGateway: 'Monetico',
            RedsysBizumPaymentGateway: 'Bizum (Redsys)',
            PaycometMbwayPaymentGateway: 'MBWAY (Paycomet)',
            PaycometBizumPaymentGateway: 'Bizum (Paycomet)',
            PaycometMultibancoSIBSPaymentGateway: 'Paycomet (Multibanco)',
            InespayPaymentGateway: 'Inespay',
            MoneiBizumPaymentGateway: 'Bizum (Monei)',
            StripePaymentGateway: 'Stripe',
            GreenPayPaymentGateway: 'GreenPay',
          },
          PAGES: {
            ADD: {
              TITLE: 'Crear',
            },
            EDIT: {
              TITLE: 'Editar',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                TYPE: 'TIPO',
                DESCRIPTION: 'DESCRIPCIÓN',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                CREATE: 'Añadir',
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_COLLECTORS: 'No hay perfiles de cobro asociados',
                NO_RESULTS: 'No hay métodos de pago que mostrar',
              },
            },
          },
        },
        COLLECTORS: {
          TITLE: 'Perfiles de cobro',
          PAGES: {},
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                ALIAS: 'ALIAS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                CREATE: 'Perfil de cobro',
              },
              MORE: {
                NO_RESULTS: 'No hay perfiles de cobro que mostrar',
              },
            },
          },
        },
        CHANNELS: {
          TITLE: 'Canales de notificación',
          ELEMENTS: {
            EmailChannelConfig: 'Email',
            SmsChannelConfig: 'SMS',
            CertifiedSmsChannelConfig: 'SMS certificado',
            CertifiedEmailChannelConfig: 'Email certificado',
          },
          PAGES: {
            ADD: {
              TITLE: 'Crear',
            },
            EDIT: {
              TITLE: 'Editar',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                TYPE: 'TIPO',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                CREATE: 'Perfil de cobro',
              },
              MORE: {
                NO_RESULTS: 'No hay canales de notificación que mostrar',
              },
            },
          },
        },
        FEEDBACK: {
          TITLE: 'Opciones de feedback',
          ELEMENTS: {},
          PAGES: {
            ADD: {
              TITLE: 'Crear',
            },
            EDIT: {
              TITLE: 'Editar',
            },
          },
          LIST: {
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                CATEGORY: 'CATEGORÍA',
                TRANSLATE: 'TRADUCCIONES',
                COLLECTORS: 'PERFILES DE COBRO ASOCIADOS',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
                CREATE: 'Perfil de cobro',
              },
              MORE: {
                NO_RESULTS: 'No hay opciones de feedback que mostrar',
              },
            },
          },
        },
      },
    },
    MODULES: {
      BEHAVIORAL_PILL: {
        TITLE: 'Píldora conductual',
        MESSAGES: {
          NO_DATA: 'Sin datos',
          COMPARE_LAST_MONTH: 'Comparado con {{recoveredLastMonth}} del mes anterior',
          COMPARE_NO_DATA: 'Comparado con - Sin datos - del mes anterior',
          RECOVERY: 'Recobro de {{ currentMonth}}',
          ABANDONED_EMAIL: 'Email abandonado',
          PILL: 'Píldora',
          PILL_ADD: '+ Añadir píldora',
          ABANDONED_GANGWAY: 'Pasarela de pago abandonada ',
          ABANDONED_PAYMENT_PAGE: 'Pagina de pago abandonada ',
          SELECT_PILL: 'Escoge una píldora para asignar ',
          SELECT_PILL_ASSIGN: 'Selecciona la píldora a asignar',
          ASSIGN_PILL: 'Asignar píldora',
          SHOW_PILL: 'Mostrar píldora',
          DELETE_CONFIRMATION: '¿Quieres eliminar la píldora {{pillName}}?',
          DELETE_HEADER: 'Eliminar Pago Parcial',
          ACCEPT_DELETE: 'Eliminar píldora',
          REJECT_DELETE: 'Cancelar',
          ACTION_FAILED: 'La acción no pudo completarse',
          NO_SELECTED: 'Píldora no seleccionada',
        },
        TOOLTIP: {
          ADD_PILL_SELECTED: 'Selecciona la píldora a asignar',
          ABANDONED_EMAIL:
            'Se lanza la píldora a los usuarios que no hicieron click al botón pagar al cabo de 30 minutos de abrir el email',
          ABANDONED_PAYMENT_PAGE:
            'Se lanza la píldora a los usuarios que no hicieron click a pagar 10 minutos después de visitar la página de pago ',
          ABANDONED_GANGWAY:
            'Se lanza la píldora a los usuarios que no completaron el pago 5 minutos después de entrar en la pasarela de pago',
        },
      },
      CUSTOMER_JOURNEY: {
        ERRORS: {
          NO_DATA: 'No hay datos disponibles',
          SHOULD_CONFIG: 'La información del recorrido del cliente debe configurarse',
          PHASES: 'Error en la configuración de fases',
          PHASES_OVERLAP:
            'Existen fases superpuestas o huecos entre fases, por favor revise su configuración',
          CAMPAIGNS_WITH_DAYS_BUT_NO_PHASE:
            'Estas campañas tienen días asignados a una fase, pero esa fase no existe',
        },
        BUTTONS: {
          MIN: 'Minimizar',
          MAX: 'Maximizar',
          CAMPAIGNS_NO_POSITION: 'Campañas no posicionadas',
        },
        MESSAGES: {
          REDIRECT_CREATE: '¿Desea redirigir a la creación de campañas?',
          CREATION_PHASE: 'Creando flujo de fases ',
          CREATION_CAMPAIGN: ' Redirigir para crear campaña ',
          DAYS: 'Días',
          CAMPAIGN_NAME: 'Nombre de la campaña',
          COLLECTORS_PROFILE: 'Perfil de cobradores',
          SEGMENTS: 'Segmentos ',
          SELF_CURE_RATE: 'Tasa de autocuración ',
          RECOVERY_RATE: 'Tasa de recuperación',
          RECOVERY: 'Recuperación',
          RECOVERY_RATE_TOOLTIP: 'Promedio de tasa de recuperación durante los últimos 6 meses',
          RECOVERY_RATE_PERCENT: 'Porcentaje de tasa de recuperación',
          SEGMENTS_TOOLTIP: 'Segmentos involucrados en la campaña',
          PAYMENTS_RESOLVED_PAYMEFY:
            'Pagos totales resueltos a través de Paymefy dividido por los pagos totales recibidos',
          SEGMENTS_LABEL: 'Segmentos',
          CAMPAIGN_TYPE: 'Tipo de campaña ',
          CONTINUOUS: 'Continua',
          CAMPAIGN_NODES: 'Nodos de la campaña',
          CAMPAIGN_NODES_COUNT: 'Cantidad de nodos',
          RECOVERY_RATE_LABEL: 'Tasa de recuperación',
          OPERATIONS_SENT: 'Operaciones notificadas',
          OPERATIONS_PAID: 'Operaciones pagadas',
          CLIENTS_CONTACTED: 'Clientes contactados',
          NOTIFICATIONS_SENT: 'Notificaciones enviadas',
          AVERAGE_6_MONTH: 'Promedio de los últimos 6 meses',
          PAYMEFY_RECOVERY_TOTAL: 'Total recuperado por Paymefy',
          EXTERNAL_RECOVERY_TOTAL: 'Total recuperado por otros medios',
          SHOW_WORKFLOW: 'Mostrar flujo de trabajo',
          NO_DATA: 'Sin datos',
          LAST_MONTH_OPERATIONS: 'Operaciones del último mes',
          LAST_MONTH_NOTIFICATIONS: 'Notificaciones del último mes',
          LAST_MONTH_AVG_PAYMEFY_AMOUNT: 'Monto promedio de Paymefy por cliente el mes pasado',
          LAST_MONTH_AVG_EXTERNAL_AMOUNT: 'Monto promedio externo por cliente el mes pasado',
          SHOW: 'Mostrar',
          CANCEL: 'Cancelar',
          CREATE_CAMPAIGN_WITH: 'Crear campaña con la fase {{phaseName}}',
        },
      },
      IMPORTS: {
        TITLE: 'Importaciones',
        BUTTONS: {
          IMPORT: 'Importar',
          DOWNLOAD: 'Descargar plantillas',
        },
        TOOLTIP: {
          DOWNLOAD:
            'Plantilla base para construir tu propio archivo de importación de manera sencilla.',
        },
      },
      CALENDAR: {
        TITLE: 'Calendario',
        FORM: {
          COLLECTION_PROFILE: 'PERFIL DE COBRO',
        },
      },
      CONVERSATIONS: {
        TITLE: 'Conversaciones',
        LABEL: {
          STARTED: 'Conversación iniciada',
          CALL_STARTED: 'Llamada iniciada',
          CALL_FINISHED: 'Llamada finalizada',
          NOTIFICATION_SENT: 'Se ha enviado la notificacion',
          OF_CAMPAIGN: 'de la campaña',
          THROUGH_CHANNEL: 'mediante el canal',
          REQUEST_CHECK_OP: 'Ha solicitado que se revisen las siguientes operaciones:',
          PAYLINK_SENT: 'Se ha enviado un paylink',
          PAYMENT_PLAN_SENT: 'Se ha enviado un plan de pago',
          NOTIFICATION_API_SENT: 'Se ha enviado una notificación mediante API.',
          NO_CONVERSATIONS: 'No hay conversaciones',
        },
      },
      BUSINESS_INSIGHTS: {
        TITLE: 'Business Insights',
      },
      LAYOUT: {
        ACTIVITY: {
          TITLE: 'Resumen de actividades',
          LABEL: {
            ACTIVITIES: 'Actividades',
          },
          OPTIONS: {
            PAYLINK_NOTIFICATION_SENT: 'Notificaciones',
            PAYMENT_ORDER_CAPTURED: 'Pagos',
            FEEDBACK_SENT: 'Feedbacks',
          },
          MESSAGES: {
            API: 'Has enviado una notificación para {{customerName}} ({{externalId}}) desde {{source}}',
            CAMPAIGN:
              'Has enviado una notificación a {{customerName}} ({{externalId}}) desde {{source}}',
            PAYMENT_AGREEMENT: 'Has enviado un acuerdo de pago a {{customerName}} ({{externalId}})',
            REMINDER: 'Has enviado un enlace de pago a {{customerName}} ({{externalId}})',
            DEFAULT: 'Has enviado una notificación a {{customerName}} ({{externalId}})',
            PAYMENT_RECEIVED:
              'Has recibido un pago por importe de {{amount}} de {{customerName}} ({{externalId}})',
            FROM: 'desde {{source}}',
            FEEDBACK: '{{customerName}} ({{externalId}}) te ha dejado feedback',
          },
        },
      },
      SHARED: {
        COMPONENTS: {
          SEND_REMINDER: {
            TITLE: 'Enviar recordatorio',
            DESCRIPTION: 'Enviar recordatorio',
            PHONE_SEND_MSG: 'Enviarás un recordatorio al siguiente teléfono: ',
            EMAIL_SEND_MSG: 'Enviarás un recordatorio al siguiente email: ',
            MONTH: 'mes',
            MONTHS: 'meses',
            PAYMENT_PROPOSAL_EXPIRATION:
              'La propuesta de acuerdo de pago caducará en <b>{{ hours }} horas</b> a partir del momento del envío.',
            DESCRIPTION_MSG:
              'Enviarás un acuerdo de pago preaprobado a <b>{{ customerName }}</b> por valor total de <b>{{ totalAmount }}</b>',
            FIRST_PAYMENT:
              'Se cargarán <b>{{ firstPayment }}</b> en el momento del primer pago, las cuotas restantes se cargarán automáticamente cada mes durante <b>{{ nMonths }} {{ monthText }}</b>, al canal de pago <b>{{ sms }} {{ email }}</b>',
            EXACT_DATES:
              'El cliente podrá ver las fechas exactas de cada cobro antes del primer pago.',
            INSTALLMENTS_TABLE_HEADER: {
              INSTALLMENT: 'Cuota',
              AMOUNT: 'Importe',
            },
            FORM: {
              ACTIONS: {
                SEND: 'Enviar',
                CONFIRM: 'Confirmar',
                CONFIRM_MESSAGE: '¿Quieres enviar el recordatorio?',
              },
              CHANNEL: {
                LABEL: 'Seleccionar canales',
              },
            },
          },
          SEND_PAYMENT_AGREEMENT: {
            TITLE: 'Enviar acuerdo de pago',
            DESCRIPTION: 'Enviar acuerdo de pago',
            PHONE_SEND_MSG: 'Enviarás un acuerdo de pago al teléfono: ',
            EMAIL_SEND_MSG: 'Enviarás un acuerdo de pago al email: ',
            SUCCESS: 'Acuerdo de pago enviado correctamente',
            ERROR: 'Error al enviar el acuerdo de pago',
            HEADER_SUCCESS: 'Acción realizada',
            HEADER_ERROR: 'Error',
            FORM: {
              ACTIONS: {
                SEND: 'Enviar',
                CONFIRM: 'Confirmar',
                CONFIRM_MESSAGE: '¿Quieres enviar el acuerdo de pago?',
              },
              CHANNEL: {
                LABEL: 'Seleccionar canales',
                INSTALMENTS: 'Número de cuotas',
              },
            },
          },
          PARTIAL_PAYMENT: {
            SMS: 'SMS',
            EMAIL: 'Correo electrónico',
            MINIMUM_AMOUNT: 'Importe mínimo',
            MINIMUM_PERCENTAGE: 'Importe mínimo %',
            SUCCESS_TITLE: 'Oferta de Pago Parcial Enviada',
            SUCCESS_MESSAGE: '¡La oferta se ha enviado con éxito!',
            ERROR_TITLE: 'Error',
            ERROR_MESSAGE:
              'No se pudo enviar la oferta de pago parcial. Es posible que ya se haya enviado.',
            TITLE: 'Configuración de Pago Parcial',
            SEND_BTN: 'Enviar pago parcial',
            TOTAL_DEBT: 'debe un total de',
            SEND_PROPOSAL_MINIMUM:
              'le enviarás una propuesta de pago parcial por importe mínimo de',
            SEND_PROPOSAL_PERCENTAGE:
              'le enviarás una propuesta de pago parcial por importe mínimo de',
            EXPIRES_72H: 'Caducará en 72 horas a partir del momento del envío',
            FORM: {
              MIN_AMOUNT: 'Importe mínimo',
              MIN_AMOUNT_PERCENT: 'Importe mínimo en %',
              CHANNEL: 'Canal',
              TEMPLATE: 'Plantilla',
            },
          },
        },
      },
      PROFILE: {
        TITLE: 'Información Personal',
        DESCRIPTION: 'Actualiza tus datos personales',
        INFO: 'Información personal',
        INFO_UPDATE: 'Actualiza tus datos personales',
        FORM: {
          NAME: 'NOMBRE',
          SURNAME: 'APELLIDOS',
          EMAIL: 'EMAIL',
          ROLE: 'ROL',
          LANGUAGE: 'LENGUAJE',
        },
        TOAST: {
          SUCCESS: 'Usuario Actualizado',
          ERROR: 'Error al actualizar el usuario',
          ERROR_HEADER: 'Error',
          SUCCESS_HEADER: 'Acción realizada',
        },
      },
      LISTING: {
        BC: {
          NOTIFICATIONS: 'Notificaciones enviadas',
          CONTACT_ERRORS: 'Errores contactabilidad',
          ABANDONED_PAYMENTS: 'Abandonos pasarela de pago',
          PAYMENTS: 'Listado de pagos',
          OP_NOTIFIED: 'Operaciones notificadas',
          BLOCKED_PHONES: 'Móviles bloqueados y teléfonos no notificables para SMS',
        },
        TOOLTIP: {
          CONTACT_ERRORS:
            'El rango de fecha seleccionado corresponde a la fecha del primer intento de entrega de la notificación.',
          ABANDONED_PAYMENTS:
            'El rango de fecha seleccionado corresponde a la fecha y hora de los clics al botón de pago que no han terminado en pago completado.',
          NOTIFICATION:
            'El rango de fecha seleccionado corresponde a la fecha de envío de la notificación.',
          PAYMENTS:
            'El rango de fecha seleccionado corresponde a la fecha y hora de confirmación del pago por parte de la pasarela de pago.',
          BLOCKEDPHONES:
            'El rango de fecha seleccionado corresponde a la fecha y hora de los móviles y teléfonos no notificables.',
        },
        TYPES: {
          ABANDONED_PAYMENTS: 'Abandonos_pasarela_pago_',
          NOTIFICATION: 'Notificaciones_',
          CONTACT_ERRORS: 'Errores_contactabilidad_',
          PAYMENTS: 'Pagos_',
        },
        LABELS: {
          ABANDONED_PAYMENT: 'Abandonos pasarela de pago',
          NOTIFICATION: 'Notificaciones',
          CONTACT_ERROR: 'Errores de contactabilidad',
          PAYMENTS: 'Pagos',
        },
        TITLE: 'Listados',
        GENERATE: {
          TITLE: 'Generar un listado',
          GENERATE: 'Generar listado',
          FORM: {
            COLLECTOR: 'PERFIL DE COBRO',
            TIME_RANGE: 'RANGO DE TIEMPO',
          },
        },
        TYPE: {
          ABANDONED_PAYMENT: {
            TITLE: 'Listados generados',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No hay abandonos pasarela de pago que mostrar',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'NOMBRE LISTADO',
                  DATE_CREATED: 'FECHA DE CREACIÓN',
                  TIME_RANGE: 'RANGO DE TIEMPO',
                  N_RESULTS: 'RESULTADOS',
                  STATUS: 'ESTADO',
                  COLLECTORS: 'PERFILES DE COBRO',
                  ACTIONS: 'ACCIONES',
                },
              },
            },
          },
          NOTIFICATION_LIST: {
            TITLE: 'Listados generados',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No hay notificaciones que mostrar',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'NOMBRE LISTADO',
                  DATE_CREATED: 'FECHA DE CREACIÓN',
                  TIME_RANGE: 'RANGO DE TIEMPO',
                  N_RESULTS: 'RESULTADOS',
                  STATUS: 'ESTADO',
                  COLLECTORS: 'PERFILES DE COBRO',
                  ACTIONS: 'ACCIONES',
                },
              },
            },
          },
          CONTACT_ERROR: {
            TITLE: 'Listados generados',
            LIST: {
              TABLE: {
                NO_RESULTS: 'No hay errores de contactabilidad que mostrar',
                HEADERS: {
                  ID: 'ID',
                  NAME: 'NOMBRE LISTADO',
                  DATE_CREATED: 'FECHA DE CREACIÓN',
                  TIME_RANGE: 'RANGO DE TIEMPO',
                  N_RESULTS: 'RESULTADOS',
                  STATUS: 'ESTADO',
                  COLLECTORS: 'PERFILES DE COBRO',
                  ACTIONS: 'ACCIONES',
                },
              },
            },
          },
        },
      },
      INSTANCES: {
        TITLE: 'Expedientes',
        CONFIG: {
          OPEN: 'El expediente aún tiene cobros pendientes y puede recibir notificaciones por email, SMS o llamadas.',
          CLOSED:
            'El expediente no tiene cobros pendientes y deja de recibir cualquier tipo de notificación',
          SILENCED: 'Expediente silenciado',
        },
        MASSIVE_SELECTION: {
          SELECTED: {
            SELECTED_COUNT:
              'Has seleccionado {{countOp}} operaciones agrupadas en {{count}} expedientes',
            FILTERS: 'Filtros',
            NO_FILTERS: 'No hay filtros aplicados',
            COLLECTORS_COUNT: 'Perfiles de cobro: {{count}} núm.',
            CAMPAIGNS_COUNT: 'Campañas: {{count}} núm.',
            STATUS: 'Estado',
            AMOUNT_RANGE: 'Importe pendiente: {{min}} a {{max}} {{currency}}',
            MORE_ITEMS: '+{{count}} más',
            STATUS_ACTIVE: 'Activo',
            STATUS_INACTIVE: 'Inactivo',
            SINGLE: 'Has seleccionado',
            OPERATIONS: 'operaciones',
            GROUPED_IN: 'agrupadas en',
            ALL_SELECTED: 'Seleccionar todos los {{ totalResults }} expedientes',
            ASSIGN_PILL: 'Asignar a píldora',
            SEND_MASSIVE_PILL: 'Asignar expedientes a píldoras masivamente',
            ARE_YOU_SURE:
              '¿Estás seguro de que deseas proceder con esta acción? Comprueba los datos siguientes antes de proceder con esta acción:',
          },
          MASSIVE_SELECTED: {
            DELETE: 'Eliminar selección masiva',
          },
        },
        FORM: {
          LABELS: {
            COLLECTOR_IDS: 'Perfiles de Cobros',
            STATUSES: 'Estado Expedientes',
            WORKFLOW_IDS: 'Campañas',
            HAS_PASSED: '¿Ha pasado por alguna campaña?',
            MIN_PENDING_AMOUNT: 'Importe Pendiente Mínimo',
            MAX_PENDING_AMOUNT: 'Importe Pendiente Máximo',
            PENDING_AMOUNT: 'Importe Pendiente',
            SEARCH: 'Búsqueda',
            SEGMENT_ID: 'Segmentos',
          },
          PLACEHOLDERS: {
            SELECT_OPTION: 'Selecciona una opción',
          },
        },
        ELEMENTS: {
          STATUS: {
            ACTIVE: 'Activo',
          },
        },
        LIST: {
          FORM: {
            SEARCH_TOOLTIP: `Busca por External ID de operación.

            También puedes buscar varios elementos separados por coma.

            Ejemplo: Op1234, Op1235, Op1236`,
          },
          TITLE: 'Listado de expedientes',
          TABLE: {
            NO_RESULTS: 'No hay expedientes que mostrar',
            NO_OPERATIONS: 'No hay operaciones que mostrar',
            HEADERS: {
              STATUS: 'ESTADO',
              CUSTOMER_NAME: 'NOMBRE CLIENTE',
              CUSTOMER: 'ID CLIENTE',
              COLLECTOR: 'PERFIL DE COBRO',
              PORTFOLIO: 'CARTERA',
              CAMPAIGN: 'CAMPAÑA',
              N_OPERATIONS: 'Nº DE OPERACIONES',
              TOTAL_AMOUNT: 'IMPORTE TOTAL',
              PENDING_AMOUNT: 'IMPORTE PENDIENTE',
              DATE: 'FECHA CREACIÓN',
              ACTIONS: 'ACCIONES',
              SUBHEADERS: {
                STATUS: 'ESTADO',
                ID_OPERATION: 'ID OPERACIÓN',
                TITLE: 'TÍTULO',
                AMOUNT: 'IMPORTE',
                DUE_DATE: 'FECHA DE VENCIMIENTO',
              },
            },
          },
        },
      },
      CAMPAINGS: {
        TITLE: 'Campañas',
        CAMPAING: 'Campaña',
        CAMPAING_NAME: 'Nombre de la campaña',
        CAMPAING_TYPE: 'Tipo de campaña',
        NO_ACTIONS: 'No hay acciones disponibles',
        CALENDAR: 'Calendario',
        CAMPAIGN_GENERATOR: 'Generar campaña',
        ERROR: {
          TITLE: 'Error',
          UNKNOWN: 'Ocurrió un error desconocido',
          ID_NOT_FOUND: 'ID de campaña no encontrado',
        },
        PROGRESS: {
          TITLE: 'Progreso',
          CREATING: 'Creando la campaña...',
        },
        SUCCESS: {
          TITLE: 'Éxito',
          CREATED: 'Campaña creada correctamente',
        },
        STEPPER: {
          CAMPAIGN: 'Campaña',
          SEGMENTATION: 'Segmentación',
          STRATEGY: 'Estrategia',
          CHECK: 'Revisar',
        },
        WORKFLOWS: {
          WAIT: {
            INFO_WAIT_DAYS_FOR_DUE_DATE:
              'Las notificaciones configuradas a continuación se enviarán por <b>{{ partDay }}</b>, <b>{{ waitDays }}</b> días <b>{{ beforeOrAfter }}</b> del vencimiento de la factura. Si el cliente tiene más de una factura se prioriza la fecha del vencimiento de la factura más antigua.',
            INFO_WAIT_DAYS:
              'Las notificaciones configuradas a continuación se enviarán por <b>{{ partDay }}</b>, dentro de <b>{{ waitDays }}</b> días.',
            INFO_WAIT_DAY_OF_WEEK:
              'Las notificaciones configuradas a continuación se enviarán por <b>{{ partDay }}</b>, el <b>{{ dayOfWeek }}</b>.',
            INFO_WAIT_DAY_OF_MONTH:
              'Las notificaciones configuradas a continuación se enviarán por <b>{{ partDay }}</b>, el día <b>{{ dayOfMonth }}</b> de este mes.',
          },
          EDIT_WORKFLOW: 'Editar flujo de trabajo',
          SAVE_CHANGES: 'Guardar cambios',
          SAVE_CHANGES_WITH_ADVERT: 'Guardar cambios con advertencias',
          BEFORE_SAVE: 'Antes de guardar, ten en cuenta que:',
          THERE_IS_ERRORS: 'Hay errores en la configuración del nodo',
          ARE_YOU_SURE: '¿Estás seguro de que deseas guardar los cambios así?',
          CANCEL: 'Cancelar',
          HAS_TO_SOLVE: 'Los siguientes errores deben resolverse:',
          NODE_FORM: {
            TIME_SLOT: 'Franja horaria de envío',
            TIME_SLOT_MORNING: 'Mañana (9:00 AM - 2:00 PM)',
            TIME_SLOT_EVENING: 'Tarde (2:00 PM - 8:00 PM)',
            TIME_SLOT_FULL_DAY: 'Todo el día (9:00 AM - 8:00 PM)',
            WAIT_DAYS: 'Días',
            WAIT_DAYS_PLACEHOLDER: 'Introduce los días de espera',
            DAY_OF_WEEK: 'Día de la semana',
            SELECT_OPTION: 'Selecciona una opción',
            DAY_OF_MONTH: 'Día del mes',
            TEMPLATES: 'Plantillas',
            MESSAGE: 'Mensajes',
            VOICE: 'Voz',
            STATUS: 'Estado',
            PERCENTAGE: 'Porcentaje del experimento',
            SEGMENTS: 'Segmentos',
            MINIMUM_AMOUNT: 'Monto mínimo',
            MINIMUM_AMOUNT_TAN: 'Monto mínimo en %',
            SEND_ALL_CONTACTS:
              'Envía este mensaje a todas las direcciones de canal disponibles para cada cliente',
            RECEIVED: 'Recibido',
            ASSIGN_CAMPAIGN: 'Asignar a campaña',
            BEFORE_AFTER: 'Antes o después del vencimiento',
            NUMBER_FEE: 'Número de cuotas',
            DURATION_DAYS: 'Duración en días',
            EXPIRATION: 'Vencimiento',
            BEFORE: 'Antes',
            AFTER: 'Después',
            EXPIRATION_DAYS: 'Días de vencimiento',
            READ: 'Leído',
            ERROR: 'Error',
            DAYS: {
              MONDAY: 'Lunes',
              TUESDAY: 'Martes',
              WEDNESDAY: 'Miércoles',
              THURSDAY: 'Jueves',
              FRIDAY: 'Viernes',
              SATURDAY: 'Sábado',
              SUNDAY: 'Domingo',
            },
          },
          NODE_TYPE: {
            WAITS: 'Esperas',
            NOTIFICATIONS: 'Notificaciones',
            CONDITIONAL: 'Condicional',
            FLEX_OPTIONS: 'Opciones de flexibilidad',
            CALL_OPTIONS: 'Opciones de llamada',
            END: 'Final',
            OFFER_PAYMENT_AGREEMENT_LINE1: 'Acuerdo',
            OFFER_PAYMENT_AGREEMENT_LINE2: 'de pago',
            OFFER_PARTIAL_LINE_1: 'Pago',
            OFFER_PARTIAL_LINE_2: 'parcial',
          },
          CALENDAR: {
            EVENT_TITLE: 'Título',
            EVENT_DESCRIPTION: 'Descripción',
            EVENT_START_DATE: 'Fecha de inicio',
            EVENT_END_DATE: 'Fecha final',
            EVENT_TYPE: 'Tipo',
            NEW_EVENT: 'Nuevo evento',
            EDIT_EVENT: 'Editar evento',
            DISPLAY_EVENT: 'Evento',
            DESCRIPTION_LABEL: 'Descripción',
            START_DATE_LABEL: 'Inicio',
            END_DATE_LABEL: 'Final',
            TYPE_LABEL: 'Tipo',
          },
          VALIDATIONS: {
            INVALID_LENGTH_OF_NODES: 'Deben haber mínimo 3 nodos en el diagrama',
            NOT_CONNECTED_TO_END:
              'Hay una rama del diagrama no conectada a un nodo tipo finalización',
            INVALID_NUMBER_OF_START_NODES: 'Número incorrecto de nodos tipo inicio',
            NO_END_NODE: 'No existen nodos de tipo finalización',
            NO_PARENT_NODE: 'Hay nodos tipo evento que no tienen un nodo padre conectado',
            NO_CHILD_NODE: 'Hay nodos tipo evento que no tienen un nodo hijo conectado',
            DUPLICATE_NODE_IDS: 'Existen nodos duplicados',
            NO_COPY_IDS: 'Debe haber plantillas seleccionadas para el nodo {{nodoType}}',
            NO_TARGET_IDS: 'Debe seleccionar una campaña objetivo para el nodo {{nodoType}}',
            NO_TARGET_NODE: 'Debe seleccionar un nodo objetivo para el nodo {{nodoType}}',
            NO_STATUS: 'Debe seleccionar al menos un estado para el nodo {{nodoType}}',
            NO_SEGMENTS: 'Debe seleccionar al menos un segmento para el nodo {{nodoType}}',
            NO_MIN_AMOUNT: 'Debe añadir un importe mínimo para el nodo {{nodoType}}',
            EXCEEDED_PERCENTAGE: 'El porcentaje debe mantenerse entre 1 y 99',
            EXCEEDED_CADUCITY: 'La caducidad debe mantenerse entre 1 y 90 días',
            EXCEEDED_INSTALMENTS: 'El número de cuotas debe mantenerse entre 2 y 11',
            NO_EXPIRATION: 'Debe añadir un día de caducidad para el nodo {{nodoType}}',
            NO_PART_DAY: 'Debe añadir días de espera para el nodo {{nodoType}}',
            NO_DAY_OF_MONTH: 'Debe seleccionar día del mes para el nodo {{nodoType}}',
            NO_WAIT_DAYS: 'Debe añadir días de espera para el nodo {{nodoType}}',
            NO_BEFORE_AFTER:
              'Debe seleccionar antes o después del vencimiento para el nodo {{nodoType}}',
            ERROR_DAY_BEFORE_AFTER:
              'Hay error en los días configurados en relación al campo Antes o Después, puede que el valor sea incorrecto para el nodo {{nodoType}}',
            NO_DAY_OF_WEEK: 'Debe seleccionar un día de la semana para el nodo {{nodoType}}',
            NO_CASE_CONDITIONAL:
              'Debe haber seleccionado y configurado un caso falso o verdadero para el nodo {{nodoType}}',
            NO_VOICE_ID: 'Debe añadir un código de voz para el nodo {{nodoType}}',
          },
        },
        SEGMENTS: {
          CONDITIONS: {
            FIELD: 'Campo {{field}} con valor: {{value}}',
            CUSTOM_FIELD: 'Campos personalizados',
            CUSTOMER_CASE_OVERDUE_DAYS: 'Antigüedad de la deuda',
            PORTFOLIO_IDS: 'Carteras',
            CUSTOMER_AGE: 'Edad del cliente',
            CUSTOMER_GENDER: 'Género',
            CUSTOMER_CASE_PENDING_AMOUNT: 'Importe Pendiente',
            COLLECTOR_IDS: 'Perfiles de cobro',
            CUSTOMER_IS_COMPANY: 'Titularidad',
            BUSINESS: 'Empresa',
            OP_PENDING: 'operaciones pendientes',
            AGE: 'años',
            OVERDUE_DAYS: 'días de deuda',
            FROM: 'Desde ',
            FROM_TO: 'Desde {{from}} a {{to}} {{key}}',
            OR: 'O bien,',
            UNTIL: 'hasta ',
            PRIVATE: 'Privado',
            FILTER_NOT_VALID: 'Filtro no válido',
            NUMB_OPERATIONS: 'Importe pendiente',
          },
          CONDITION_NAME_NOT_FOUND: 'No se encontró el nombre para la clave especificada.',
          NO_SEGMENTS_WIZARD:
            'No has seleccionado ningún segmento específico. Esto significa que TODOS LOS NUEVOS EXPEDIENTES de los perfiles de cobro [listado de perfiles de cobro seleccionados en el paso anterior] podrían incluirse en esta campaña. ¿Estás seguro de que deseas continuar?',
          MODAL: {
            HEADER: 'Crear un nuevo segmento',
            SAVE: 'Guardar cambios',
            LOADING: 'Cargando...',
            ADD_NEW: 'Añadir nuevo',
            INCLUDE_SEGMENTS: 'Incluir segmentos',
            SAVE_SEGMENTS: 'Guardar segmento',
            COLLECTOR_PROFILE: 'Perfil de Cobro',
            PORTFOLIO: 'Cartera',
            MIN_PENDING_AMOUNT: 'Importe Pendiente Mínimo',
            MAX_PENDING_AMOUNT: 'Importe Pendiente Máximo',
            MIN_OVERDUE_DAYS: 'Antigüedad de Deuda Mínima',
            MAX_OVERDUE_DAYS: 'Antigüedad de Deuda Máxima',
            MIN_PAYABLE_OPERATIONS: 'Número Operaciones Pendientes Mínima',
            MAX_PAYABLE_OPERATIONS: 'Número Operaciones Pendientes Máxima',
            MIN_AGE: 'Edad Mínima',
            MAX_AGE: 'Edad Máxima',
            OWNERSHIP: 'Titularidad',
            GENDER: 'Género',
            NAME_SEGMENT: 'Nombre de segmento',
            VALUE: 'Valor',
            THE_SEGMENT: 'El segmento: ',
            VAR_FIELD: 'Campo variable',
            CUSTOM_FIELD: 'tiene el campo variable {{field}} cuyo valor es {{value}}',
            COLLECTOR_IDS: 'tiene el/los perfil/es de cobro: {{collectors}}',
            PORTFOLIO_IDS: 'se ha seleccionado la(s) cartera(s): {{portfolios}}',
            CUSTOMER_CASE_PENDING_AMOUNT:
              'el importe pendiente es entre {{minAmount}} {{minCurrency}} y {{maxAmount}} {{maxCurrency}}',
            CUSTOMER_CASE_OVERDUE_DAYS:
              'la antigüedad de la deuda es entre {{minDays}} días y {{maxDays}} días',
            CUSTOMER_CASE_NUMBER_OF_PAYABLE_OPERATIONS:
              'el número de operaciones es entre {{minOperations}} y {{maxOperations}}',
            CUSTOMER_AGE: 'la edad del cliente está entre {{minAge}} años y {{maxAge}} años',
            CUSTOMER_IS_COMPANY: 'el titular es {{ownership}}',
            CUSTOMER_GENDER: 'el género es {{genders}}',
            DEFAULT: '{{type}}: {{value}}',
            START_FILLING: 'Empieza a rellenar el formulario para ver la info',
            CONDITIONAL: 'Y',
            INFO_TOOLTIP_VAR_FIELD:
              'Campo tipo placeholder dominio del cliente definido en el fichero de importación',
            VALIDATION: {
              MAX_FIELD_REQUIRED:
                'El campo máximo es obligatorio si el campo mínimo tiene un valor.',
              MIN_FIELD_REQUIRED:
                'El campo mínimo es obligatorio si el campo máximo tiene un valor.',
              SAME_VALUE_NOT_ALLOWED: 'No puede ser el mismo valor.',
              MIN_GREATER_THAN_MAX: 'El valor mínimo no puede ser mayor que el valor máximo.',
              MAX_LESS_THAN_MIN: 'El valor máximo no puede ser menor que el valor mínimo.',
              BOTH_FIELDS_FILLED: 'Los dos campos deben estar llenos',
            },
          },
          TOAST: {
            ERROR: {
              TITLE: 'Error',
              MESSAGE: 'Revisa la información del segmento',
            },
            SUCCESS: {
              TITLE: 'Segmento creado',
              MESSAGE: 'El segmento {{name}} se ha creado correctamente',
            },
          },
          SUCCESS: {
            TITLE: 'Campaña Actualizada',
            MESSAGE: 'La campaña se ha actualizado correctamente.',
          },
          ERROR: {
            TITLE: 'Error al Actualizar',
            MESSAGE: 'No se ha podido actualizar la campaña.',
          },
          EDIT: {
            HEADER: 'Editar Información de la Campaña',
          },
          SELECT_SEGMENT: 'Selecciona al menos un segmento',
          NO_OPTIONS: 'No hay opciones de segmentación',
          LOADING_SEGMENTS: 'Cargando segmentos',
          EDIT_SEGMENTS: 'Editar segmentos',
        },
        TYPE: {
          PILL: 'Píldora',
          DRIP: 'Continua',
        },
        STATUS: {
          ACTIVE: 'activa',
          PAUSED: 'pausada',
          DRAFT: 'borrador',
          PUBLISHED: 'publicada',
          UNPUBLISHED: 'archivada',
          NO_WORKFLOW: 'Recordatorio o link directo',
        },
        GENERIC_INFO: {
          NO_NAME: 'Nombre no disponible',
          EDIT_INFORMATION: 'Editar información',
          NO_TRIGGER: 'Sin disparadores',
          START_DAYS: '{{days}} días desde el inicio',
          FINISH_DAYS: '{{days}} días',
          NO_PHASE: 'Sin fase',
          EDIT_CAMPAIGN_INFORMATION: 'Editar información de la campaña',
          ENTER_CRITERION: 'Criterio de entrada de la campaña',
          ENTER_CRITERIA: {
            SAVE_CRITERIA: 'Guardar criterios de entrada',
            TOOLTIP_EDIT: 'Editar criterios de entrada',
            PAYMENT_PROFILES: 'Perfiles de cobro',
            NO_PAYMENT_PROFILES: 'No hay perfiles de cobro disponibles.',
            ACTIVE_CASES: 'Expedientes activos: {{caseCount}}',
            OR: 'O bien,',
            ADDITIONALLY: 'Además',
            SEGMENTS: 'Segmentos',
            NO_SEGMENTS: 'No hay segmentos disponibles.',
            FILTERS: 'Filtros: {{filterCount}}',
            CONDITION_NAME: '{{conditionName}}',
            CONDITION_VALUE: '{{conditionValue}}',
            VIEW_COLLECTOR: 'Ver otros perfiles de cobro',
            HIDDE_COLLECTOR: 'Ocultar perfiles de cobro',
            HIDDE_SEGMENTS: 'Ocultar segmentos',
            VIEW_SEGMENTS: 'Ver otros segmentos',
            ACTIVE_PROFILES: 'Perfil de cobro activos',
            ACTIVE_PROFILES_TOOLTIP:
              'Los expedientes que cumplan con los criterios de entrada de estos perfiles de cobro se incorporarán automáticamente a la campaña y serán registrados como expedientes activos',
            DISCARDED_PROFILES: 'Perfil de cobro descartados',
            DISCARDED_PROFILES_TOOLTIP:
              'No entrarán nuevos expedientes en estos perfiles de cobro. Seguirán visibles mientras haya expedientes activos en campaña y se ocultarán automáticamente cuando todos finalicen.',
          },
          TYPE: {
            PILL: 'Campaña tipo Píldora',
            CONTINUOUS: 'Campaña tipo Contínua',
            PILL_SIMPLE: 'Píldora',
            DRIP_SIMPLE: 'Contínua',
          },
          INPUTS: {
            NUMBER_NODES: 'Número de nodos',
            STATUS: 'Estado',
            TYPE: 'Tipo de campaña',
            NAME: {
              LABEL: 'Nombre de la campaña',
              PLACEHOLDER: 'Nombre de la campaña',
            },
            PHASES: {
              LABEL: 'Fase',
              PLACEHOLDER: 'Selecciona una fase',
            },
            COLLECTORS: {
              LABEL: 'Perfiles de cobro',
              PLACEHOLDER: 'Selecciona perfiles de cobro',
            },
            START: {
              LABEL: 'Inicio de fase',
              ADDON: 'días',
            },
            FINISH: {
              LABEL: 'Fin de fase',
              ADDON: 'días',
            },
            TRIGGERS: {
              LABEL: 'Comportamiento',
              PLACEHOLDER: 'Selecciona un evento',
            },
          },
          ERRORS: {
            PHASE_START_TOO_EARLY:
              'El valor de Inicio no puede ser menor que el inicio más temprano permitido por las fases configuradas.',
            PHASE_END_TOO_LATE:
              'El valor de Fin no puede ser mayor que el fin más tardío permitido por las fases configuradas.',
            SAME_PHASE_VALUE: 'Los valores no pueden ser el mismo.',
            RANGE_INVALID: 'El valor Inicio no puede ser mayor que el valor Fin.',
          },
          BUTTONS: {
            SAVE: 'Guardar cambios',
          },
          TOAST: {
            SUCCESS: {
              UPDATED: {
                TITLE: 'Campaña actualizada',
                MESSAGE: 'La campaña se ha actualizado correctamente',
              },
            },
            ERROR: {
              UPDATED: {
                TITLE: 'Error por la campaña actualizada',
                MESSAGE: 'No se ha podido actualizar la campaña',
              },
            },
          },
          MODAL: {
            HEADER: 'Editar información de la campaña',
          },
        },
        NEW: 'Nueva campaña',
        CLONE: {
          ACTIONS: {
            CLONE: 'Clonar',
          },
          FORM: {
            NAME: 'NUEVO NOMBRE',
          },
        },
        ELEMENTS: {
          NODES: {
            ACTIVE_CASES: {
              N_CASES: 'Nº de expedientes activos: {{count}}',
              NO_CASES: 'Sin expedientes activos',
            },
            TYPES: {
              EVENT: 'Evento',
              ACTIVITY: 'Actividad',
              CONDITION: 'Condición',
            },
            NAMING: {
              waitDays: 'Esperar días',
              waitDayOfWeek: 'Esperar días semana',
              waitDaysForDueDate: 'Esperar vencimiento',
              waitDayOfMonth: 'Esperar días mes',
              sendSMS: 'SMS',
              sendCertifiedSMS: 'SMS certificado',
              sendEmail: 'Email',
              sendCertifiedEmail: 'Email certificado',
              superSendSMS: 'SMS +',
              superSendCertifiedSMS: 'SMS certificado +',
              superSendEmail: 'Email +',
              superSendCertifiedEmail: 'Email certificado +',
              paylinkNotificationStatus: 'Sí (notificación)',
              customerCaseSegments: 'Sí (segmento)',
              offerPartialPayment: 'Pago parcial',
              offerPaymentAgreement: 'Acuerdo de pago',
              assignCampaign: 'Asignar a campaña',
              makeOutboundCall: 'Llamada saliente',
              start: 'Inicio',
              experiment: 'Experimento',
              end: 'Fin',
              email_plus: 'Correo electrónico +',
              email_plus_certified: 'Correo electrónico certificado +',
              sms_plus_certified: 'SMS certificado +',
              sms_plus: 'SMS +',
            },
            PARAMETERS: {
              TARGET_NODE: 'Nodo Objetivo',
              SELECT_NODE: 'Selecciona un nodo objetivo',
              SUBJECT: 'Asunto',
              PRE_HEADER: 'Pre Header',
              MESSAGE: 'Mensaje',
              BUTTON_TEXT: 'Texto del botón',
              COPY_MESSAGE: 'Copiar mensaje',
              PARTIAL_PAYMENT_PROPOSAL:
                'Se enviará una propuesta de pago parcial por valor mínimo de',
              EXPIRATION_DAYS: 'Caducará en',
              NUMBER_OF_INSTALLMENTS: 'Se enviará una propuesta de acuerdo de pago de',
              INSTALLMENTS: 'cuotas',
              FROM_NOW: ' a partir del momento de envío.',
              DAYS_UNTIL: 'Días hasta la expiración:',
              NUMBER_INSTALMENTS: 'Número de cuotas:',
              INSTALMENTS_IN: 'cuotas en',
              MINIMUM_AMOUNT: 'Monto mínimo:',
              MINIMUM_PERCENTAGE: 'Porcentaje mínimo:',
              EXPERIMENT:
                'El <b>{{percentage}} %</b> de los expedientes seguirá la rama ✔ para el experimento, mientras que el resto seguirá la rama ✘',
              CUSTOMER_PAYMENT_DATES:
                'El cliente podrá ver las fechas exactas de cada cobro antes del primer pago.',
              CONFIGURATION: 'Configuración',
              SELECT_TARGET_NODE: 'Selecciona un nodo objetivo',
              NODE_OBJECTIVE: 'Nodo objetivo',
              EXACT_DAY:
                'El cliente podrá ver las fechas exactas de cada cobro antes del primer pago.',
              FROM_DAY:
                'a partir del momento de envío. Se cargará la primera cuota en el momento del primer pago, las\n' +
                '                     cuotas restantes se cargaran automáticamente cada mes durante',
              FEE: 'cuotas.',
              EMAIL: {
                SUBJECT: 'Asunto',
                PRE_HEADER: 'Pre Header',
                MESSAGE: 'Mensaje',
              },
              SMS: {
                MESSAGE: 'Mensaje',
              },
              OFFER_PARTIAL_PAYMENT: {
                INFO: `Se enviará una propuesta de pago parcial por valor mínimo de
                      <b>{{amount}}</b> EUR. Caducará en <b>{{days}}</b> días a partir del momento de envío.`,
              },
              OFFER_PAYMENT_AGREEMENT: {
                INFO: `Se enviará una propuesta de acuerdo de pago de
                      <b>{{instalments}}</b> cuotas. Caducará en
                      <b>{{days}}</b> {{days === 1 ? 'día' : 'días'}} a partir del momento de envío.
                      Se cargará la primera cuota en el momento del primer pago,
                      las cuotas restantes se cargarán automáticamente cada mes durante
                      <b>{{instalments}}</b> {{instalments === 1 ? 'mes' : 'meses'}}.
                      El cliente podrá ver las fechas exactas de cada cobro antes del primer pago.`,
              },
              PART_DAY: {
                OF: 'Envío durante: ',
                MORNING: 'Mañana',
                EVENING: 'Noche',
                FULL_DAY: 'Todo el día',
              },
              KEYS: {
                name: 'Nombre',
                waitDays: 'Días de espera',
                partDay: 'Parte del día',
                dayOfWeek: 'Día de la semana',
                dayOfMonth: 'Día del mes',
                daysForDueDate: 'Días para la fecha de vencimiento',
                copyIds: 'Copiar IDs',
                copyId: 'Copiar ID',
                targetNodeId: 'ID del nodo objetivo',
                statuses: 'Estados',
              },
              NAME: {
                start: 'Inicio',
                end: 'Fin',
                waitDays: 'Días de espera: ',
                waitDayOfWeek: 'Día de la semana: ',
                waitDayOfMonth: 'Día del mes: ',
                waitDaysForDueDate: 'Días para la fecha de vencimiento: ',
                sendSMS: 'Enviar SMS',
                sendEmail: 'Enviar Email',
                sendCertifiedSMS: 'Enviar SMS certificado',
                sendCertifiedEmail: 'Enviar Email certificado',
                superSendSMS: 'Enviar super SMS',
                superSendEmail: 'Enviar super Email',
                superSendCertifiedSMS: 'Enviar super SMS certificado',
                superSendCertifiedEmail: 'Enviar super Email certificado',
                paylinkNotificationStatus: 'Estado de la notificación',
                assignCampaign: 'Asignar campaña',
              },
            },
          },
        },
        LIST: {
          TITLE: 'Listado de campañas',
          NEW: 'Nueva campaña',
          EDIT: 'Editar campaña',
          TABLE: {
            NO_CAMPAIGNS_MESSAGE: 'No existen campañas actualmente',
            TYPES: {
              ACTIVE: 'Activas',
              DRAFT: 'Borrador',
              UNPUBLISHED: 'Archivadas',
              PILLS: 'Píldoras',
            },
            TOOLTIPS: {
              SHOW_COLLECTORS: 'Ver perfiles de cobro',
            },
            HEADERS_PILLS: {
              NAME: 'NOMBRE DE LA PÍLDORA',
              PILLS_SEND: 'PÍLDORAS ENVIADAS',
              COLLECTORS: 'PERFIL DE COBRO',
              ACTIVE_CASES: 'CASOS ACTIVOS',
              CUSTOMER_CASES_COUNT: 'EXPEDIENTES ACTIVOS',
              ACTIONS: 'ACCIONES',
              SUBHEADERS: {
                CUSTOMER_CASES_NOTIFIED: 'EXPEDIENTES NOTIFICADOS',
                TOOLTIPS: {
                  CREATED:
                    'Las instancias están siendo creandas, este proceso puede tardar unos minutos.',
                },
                STATUS: 'ESTADO',
                TIME: 'FECHA Y HORA',
                CLIENT_NAME: 'NOMBRE DEL CLIENTE',
                N_INSTANCES: 'Nº DE EXPEDIENTES',
              },
            },
            HEADERS: {
              PRIORITY: 'PRIORIDAD',
              CAMPAIGN_NAME: 'NOMBRE DE LA CAMPAÑA',
              COLLECTORS: 'PERFIL DE COBRO',
              SEGMENTS: 'SEGMENTOS',
              ACTIVE_CASES: 'EXPEDIENTES ACTIVOS',
              STATUS: 'ESTADO',
              CUSTOMER_CASES_COUNT: 'EXPEDIENTES ACTIVOS',
              ACTIONS: 'ACCIONES',
            },
            MORE: {
              PRIORITY_TOOLTIP:
                'En el caso de que varias campañas continuas compartan segmentos la prioridad decidirá a qué campaña se asignan los nuevos expedientes. Si las campañas no comparten segmentos la prioridad no tiene efecto.',
              NO_RESULTS: 'No hay campañas que mostrar',
              NO_PILLS_RESULTS: 'No hay píldoras que mostrar',
              NO_PILLS_SUBTABLE_RESULTS: 'No hay instancias que mostrar',
            },
          },
        },
        WIZARD: {
          GENERAL_DATA: 'Datos generales',
          CAMPAIGN_NAMING: 'Nombre campaña',
          COLLECTORS: 'Perfiles de cobro',
          CAMPAIGN_TYPE: 'Tipo de campaña',
          PURPOSE: 'Propósito',
          SUCCESS: 'Éxito',
          TRIGGERS: 'Comportamiento',
          PHASE_ACTIONS: 'Acción en fases',
          PHASE_ACTIONS_DESCRIPTION: '{{phaseStart}} días a {{phaseEnd}} días',
          SEGMENTATION: 'Segmentación',
          NODES: 'Nodos',
          NOT_APPLICABLE: 'No aplica',
          WARNINGS_BEFORE_SAVING: 'Antes de guardar, ten en cuenta que:',
          NODE_CONFIGURATION_ERRORS: 'Hay errores en la configuración de los nodos',
          SURE_TO_SAVE_CHANGES: '¿Seguro que quieres guardar los cambios así?',
          ERRORS_TO_PUBLISH: 'Hay que solucionar los siguientes errores para publicar la campaña:',
          ERROR_LIST: 'Hay los siguientes errores:',
          SAVE_DRAFT: 'Guardar Borrador',
          SAVE_CHANGES_WARNING: 'Guardar cambios con advertencia',
          NAME: 'Nombre de la campaña',
          CAMPAIGN_NAME: 'Ingrese el nombre de la campaña',
          PROFILE: 'Perfil de cobro',
          ACTION_EVENT: 'Evento de acción',
          SELECT_EVENT: 'Selecciona un evento',
          PHASE: 'Fase',
          SELECT_PHASE: 'Selecciona una fase',
          PHASE_START: 'Inicio de fase',
          LOADING_SEGMENTS: 'Cargando segmentos',
          SELECT_OR_CREATE_SEGMENT: 'Selecciona un segmento o crea uno nuevo.',
          CONTINUE: 'Continuar',
          CREATE_NEW_SEGMENT: 'Crear un nuevo segmento',
          OR: 'O',
          INCLUDE: 'Incluye:',
          SEGMENTS_SELECTED: 'Segmentos seleccionados',
          INFO_SEGMENTS:
            'Un registro solo será incluido si cumple todos los criterios de al menos uno de estos segmentos. No es necesario cumplir más de un segmento para ser parte de la campaña.',
          CREATED_SEGMENT: 'Segmento creado',
          INCLUDE_SEGMENT: 'Incluir segmentos',
          CREATED_SEGMENT_CORRECTLY: 'El segmento {{name}}} se ha creado correctamente',
          PHASE_END: 'Fin de fase',
          BEHAVIOR: 'Comportamiento',
          ACTION: {
            BACK: 'Atrás',
            NEXT: 'Siguiente',
          },
          ERRORS: {
            PHASE_START_TOO_EARLY:
              'El valor de Inicio no puede ser menor que el inicio más temprano permitido por las fases configuradas.',
            PHASE_END_TOO_LATE:
              'El valor de Fin no puede ser mayor que el fin más tardío permitido por las fases configuradas.',
            SAME_PHASE_VALUE: 'Los valores no pueden ser el mismo.',
            RANGE_INVALID: 'El valor Inicio no puede ser mayor que el valor Fin.',
          },
        },
        MONITORING: {
          TITLE: 'Compartir Datos ',
          //...
        },
        EDIT: {
          SUCCESS: 'Éxito!',
          PRIORITY_CHANGED: 'El orden ha cambiado',
          CAMPAIGN_STATE_CHANGED: 'El estado ha cambiado a estado',
          ERROR: 'Error',
          PAUSED: 'Pausado',
          PUBLISHED: 'Publicado',
          UNPUBLISHED: 'Despublicado',
          CAMPAIGN_PAUSE: 'La campaña ha sido pausada',
          CAMPAIGN_UNPUBLISHED: 'La campaña ha sido archivada',
          CAMPAIGN_PUBLISHED: 'La campaña ha sido publicada',
          CAMPAIGN_UPDATED: 'Campaña actualizada',
          CAMPAIGN_BEEN_UPDATED: 'Campaña actualizada',
          ERROR_SHOWN: 'Ocurrió un error inesperado',
          RESUME_COLLECTOR_ERROR: 'No se pudo reanudar el collector',
          RESUME_COLLECTOR_SUCCESS: 'El perfil de cobro se reanudó correctamente',
          PAUSE_COLLECTOR_ERROR: 'No se pudo pausar el collector',
          PAUSE_COLLECTOR_SUCCESS: 'El perfil de cobro se pausó correctamente',
          UNPUBLISH_COLLECTOR_ERROR: 'No se pudo archivar el collector',
          UNPUBLISH_COLLECTOR_SUCCESS: 'El perfil de cobro se archivó correctamente',
          TITLE: 'Editar campaña',
          DELETE_DRAFT_MESSAGE: '¿Estás seguro quieres eliminar el borrador?',
          DELETE_DRAFT_HEADER: 'Eliminar borrador {{campaignName}}',
          DELETE_DRAFT_ACCEPT_LABEL: 'Eliminar',
          DELETE_DRAFT_ACCEPT_ICON: 'pi pi-trash',
          DELETE_DRAFT_ACCEPT_BUTTON_CLASS: 'p-button-danger',
          DELETE_DRAFT_REJECT_BUTTON_CLASS: 'p-button-text',
          DELETE_DRAFT_SUCCESS_MESSAGE: 'Campaña {{campaignName}} eliminada correctamente',
          CLONE_CAMPAIGN_MESSAGE: 'Campaña {{campaignName}} clonada',
          CLONE_CAMPAIGN_HEADER: '¿Quieres clonar la campaña "{{campaignName}}"?',
          CLONE_CAMPAIGN_SUCCESS_MESSAGE: 'Campaña {{campaignName}} clonada correctamente',
        },
        COPIES: {
          TITLE: 'Plantillas',
          ELEMENTS: {},
          ADD: {
            TITLE: 'Crear plantilla',
            //...
          },
          EDIT: {
            TITLE: 'Editar Plantilla',
            TEMPLATE_UPDATED: '{{name}} ha sido editada exitosamente',
            SUCCESS_MESSAGE: '¡Éxito!',
            SAVE_TEMPLATE: 'Guardar Plantilla',
            SAVE_AUTOTEMPLATE: 'Guardar Plantilla',
            QUESTION_EDIT:
              '¿Estás seguro de que deseas guardar la plantilla? Recuerda que esta plantilla enviará {{number}} SMS a cada cliente.',
          },
        },
      },
      COPIES: {
        TITLE: 'Plantillas',
        ELEMENTS: {},
        ADD: {
          TITLE: 'Crear plantilla',
          //...
        },
        EDIT: {
          TITLE: 'Editar Plantilla',
          TEMPLATE_UPDATED: '{{name}} ha sido editada exitosamente',
          SUCCESS_MESSAGE: '¡Éxito!',
          SAVE_TEMPLATE: 'Guardar Plantilla',
          SAVE_AUTOTEMPLATE: 'Guardar Plantilla',
          QUESTION_EDIT:
            '¿Estás seguro de que deseas guardar la plantilla? Recuerda que esta plantilla enviará {{number}} SMS a cada cliente.',
        },
      },

      CUSTOMERS: {
        TITLE: 'Clientes',
        ELEMENTS: {},
        MODAL: {
          DELETE_CONFIRM_HEADER: '<p class="font-normal">Eliminar <b>{{count}}</b> clientes</p>',
          DELETE_CONFIRM_MESSAGE: '¿Seguro que quieres eliminar {{count}} clientes?',
          DELETE_SUCCESS: '{{count}} clientes eliminados',
          DELETE_FAILED: 'Hubo un error al eliminar a {{count}} clientes',
          DELETE_SINGLE_CONFIRM_HEADER:
            '<p class="font-normal">Eliminar cliente: <b>{{longName}} ({{externalId}})</b></p>',
          DELETE_SINGLE_CONFIRM_MESSAGE: '¿Seguro que quieres eliminar a {{longName}}?',
          DELETE_SINGLE_SUCCESS: '{{longName}} eliminado',
          DELETE_SINGLE_FAILED: 'Hubo un error al eliminar a {{longName}}',
          REJECT_LABEL: 'No',
          ACCEPT_LABEL: 'Sí',
          SELECT_ALL: 'Seleccionar todos los clientes',
          DELETE_SELECTION: 'Eliminar selección',
        },
        PAGES: {
          SECTIONS: {
            INFO: 'Información',
            CONTACTS: 'Contactos',
            EMAILS: 'Correos electrónicos',
            PHONE_LIST: 'Lista de teléfonos',
            EMAIL_LIST: 'Lista de correos',
            NO_PHONE_CONTACTS: 'Este usuario no tiene teléfonos de contacto',
            NO_EMAIL_CONTACTS: 'Este usuario no tiene correos de contacto',
            COPY_TEXT: 'Copiar texto',
            NOTIFYING: 'Notificando',
            BLACKLISTED: 'En la lista negra',
            LOCALIZATION: 'Localización',
            TOOLTIP_EDIT_INFO: 'Editar información del cliente',
            TOOLTIP_CREATE_CONTACT: 'Crear contacto',
            TOOLTIP_EDIT_CONTACT: 'Editar contacto',
            TOOLTIP_EDIT_LOCALIZATION: 'Editar localización',
            TOOLTIP_COPY: 'Copiar texto',
            NAME: 'Nombre',
            DNI: 'DNI',
            OWNERSHIP: 'Propiedad',
            BIRTH_DATE: 'Fecha de nacimiento',
            GENDER: 'Género',
            CONTACT_LANGUAGE: 'Idioma de contacto',
            BUSINESS: 'Empresa',
            PRIVATE: 'Privado',
            COPIED: 'Copiado',
            COPIED_SUCCESSFULLY: 'Texto copiado correctamente',
            ADDRESS: 'Dirección',
            ZIP_CODE: 'Código postal',
            CITY: 'Ciudad',
            COUNTRY: 'País',
            TOAST_CONTACT: 'Contacto creado',
            TOAST_LIST_CONTACT: 'Lista de contactos actualizada',
            TOAST_LOCALIZATION: '¡Localización de {{name}} actualizada!',
            FORMS: {
              TITLE_INFO: 'Editar información',
              TITLE_ADD_NEW_CONTACT: 'Agregar nuevo contacto',
              TITLE_EDIT_CONTACT: 'Editar contactos',
              TITLE_EDIT_LOCALIZATION: 'Editar localización',
              ID_CLIENT: 'ID Cliente',
              SHORT_NAME: 'Nombre corto',
              FULL_NAME: 'Nombre completo',
              NIE: 'DNI/NIE/CIF',
              OWNERSHIP: 'DNI/NIE/CIF',
              BIRTH_DATE: 'Fecha de nacimiento',
              CONTACT_LANGUAGE: 'Idioma de contacto',
              GENDER: 'Género',
              NEW_EMAIL: 'Nuevo correo electrónico',
              EDIT: 'Editar',
              TYPE: 'Tipo',
              NEW_TELEPHONE: 'Nuevo número de teléfono',
              CREATE_CONTACT: 'Crear contacto',
              DIRECTION: 'Dirección',
              ZIP_CODE: 'Código postal',
              TOWN: 'Población',
              COUNTRY: 'País',
            },
          },
          LIST: {
            TITLE: 'Listado de clientes',
          },
          CUSTOMER_LIST: {
            TITLE: 'Listado de clientes',
            NEW: 'Nuevo cliente',
            EDIT: 'Editar cliente',
            DELETE_BUTTON: 'Eliminar {{selected}}',
            FORM: {
              NIF: 'DNI/NIE/NIF',
              EXTERNAL_ID: 'ID CLIENTE',
              COLLECTOR: 'PERFIL DE COBRO',
              SEARCH: 'BÚSQUEDA',
              NO_EXIST_CUSTOMERS: 'No existen clientes para esta búsqueda',
              SEARCH_TOOLTIP: `Busca entre ID Cliente, Nombre completo, DNI y datos de contacto (teléfono y correo electrónico) resultados parecidos.

              También puedes buscar varios elementos separados por coma.

              Ejemplo: María Garcia, Antonio Rodriguez, Carmen Gonzalez`,
            },
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                DNI: 'DNI/NIE/NIF',
                EXTERNAL_ID: 'ID CLIENTE',
                COLLECTOR: 'PERFIL DE COBRO',
                TOTAL_AMOUNT: 'IMPORTE TOTAL',
                PENDING_AMOUNT: 'IMPORTE PENDIENTE',
                DATE: 'FECHA DE ACTUALIZACIÓN',
                ACTIONS: 'ACCIONES',
              },
              MORE: {
                NO_RESULTS: 'No hay clientes que mostrar',
              },
            },
          },
          CUSTOMER_FILE: {
            TITLE: 'Detalle cliente',
            SECTIONS: {
              SUPERIOR_INFO: {
                TOTAL_AMOUNT: 'Importe original',
                NO_PAYMENT_PENDING: '✔ Sin pagos pendientes',
                CONTACTABILITY_TOOLTIP:
                  'Se analiza la efectividad de entrega de mensajes por SMS y correos para prever si el contacto recibirá los futuros mensajes, siendo -10 no contactable y +10 alta contactabilidad',
                TOTAL_AMOUNT_TOOLTIP:
                  'Suma total de los importes pendiente de las operaciones asociadas a este cliente',
                PENDING_AMOUNT: 'Importe pendiente',
                PENDING_AMOUNT_TOOLTIP:
                  'Suma de los importes de las operaciones originalment registradas para el cliente.',
                AVERAGE_PAYMENT_TIME: 'Tiempo medio de pago',
                AVERAGE_PAYMENT_TIME_TOOLTIP:
                  'Tiempo medio entre la fecha de vencimiento y la fecha de pago de las operaciones asociadas al cliente.',
                CONTACTABILITY: 'Contactabilidad',
                NO_PAYMENTS: 'No hay pagos',
              },
              CONTACT: {
                NOTIFICABLE_PHONE: 'Este teléfono está siendo notificado',
                NOTIFICABLE_EMAIL: 'Este email está siendo notificado',
              },
              CASES: {
                TITLE: 'Expedientes',
                NO_CASES: 'No hay expedientes relacionados que mostrar',
                NO_OPERATIONS: 'No hay operaciones que mostrar',
                TABLE: {
                  HEADERS: {
                    STATUS: 'Estado',
                    PORTFOLIO: 'Cartera',
                    COLLECTOR: 'Perfil de cobro',
                    TOTAL_AMOUNT: 'Importe total',
                    PENDING_AMOUNT: 'Importe pendiente',
                    CAMPAIGN: 'Campaña',
                    TOOLTIP_CAMPAIGN: 'Última campaña que ha impactado este expediente',
                  },
                  SUBHEADERS: {
                    STATUS: 'Estado',
                    ID_OPERATION: 'ID operación',
                    TITLE: 'Título',
                    AMOUNT: 'Importe',
                    DUE_DATE: 'Fecha de vencimiento',
                    COLLECTOR: 'Perfil de cobro',
                    ACTIONS: 'Acciones',
                  },
                  TOOLTIP: {
                    PARTIAL_PAYMENT: 'Pago parcial',
                  },
                },
              },
              PAYMENTS: {
                TITLE: 'Pagos',
                NO_PAYMENTS: 'No hay pagos relacionados con este cliente.',
                VIEW_OPERATIONS: 'Ver operaciones',
                TABLE_HEADERS: {
                  PAYMENT_DATE: 'Fecha de pago',
                  AMOUNT: 'Cantidad',
                  METHOD: 'Método',
                  OPERATIONS: 'Operaciones',
                },
                OPERATIONS: {
                  ATTRIBUTED_AMOUNT: 'Importe atribuido',
                  OPERATION: 'Operación',
                  CONCEPTS: 'Conceptos',
                  NO_CONCEPTS: 'No hay conceptos disponibles',
                  NO_OPERATIONS: 'No hay operaciones para este pago.',
                },
              },
            },
          },
          ACTIVITIES: {
            ACTIVITY_TITLE: 'Actividades',
            LABEL: {
              LEGEND: 'Claves',
              TYPE_ACTIVITY: 'Tipo de actividades',
              NO_ACTIVITY: 'No hay actividad',
              SUBJECT: 'Asunto',
              PRE_HEADER: 'Pre encabezado',
              MESSAGE: 'Mensaje',
              WITH_MESSAGE: 'Con mensaje',
              REMINDER: 'un recordatorio',
              VALID_UNTIL: 'Válido hasta',
              NOTIFICATION: 'Notificación',
              SEND_TO: 'enviado a',
              FROM: 'desde',
              WITH_METHOD_PAYMENT: 'con el método de pago',
              HAS_CALLED: 'Ha sido llamado',
              SHOW_CALL: 'Mostrar llamada',
              SHOW_WITH_CLIENT: 'Llamada con cliente',
              CALL_INIT: 'Llamada iniciada',
              CALL_FINISH: 'Llamada finalizada',
              THE_TELEPHONE: 'El teléfono',
              CATALOG: 'ha sido catalogado como',
              BLACKLISTED: 'Este número está en la lista negra, no se puede contactar',
              FEEDBACK_PAGE: 'la página de comentarios',
            },
            LEGENDS_DESCRIPTION: {
              payment_order_created: 'Se ha iniciado un intento de pago',
              payment_order_canceled: 'Ha ocurrido un error en el proceso',
              payment_order_captured: 'Se ha completado el pago',
              feedback_visited: 'Se ha visitado la página de comentarios',
              feedback_sent: 'Se ha enviado un comentario',
              paylink_notification_visited: 'Se ha visitado la página de pago',
              paylink_notification_received: 'Se ha recibido una notificación',
              paylink_notification_sent: 'Se ha enviado una notificación',
              paylink_notification_read: 'Se ha leído la notificación',
              paylink_notification_login_visited: 'Se ha completado el inicio de sesión',
              paylink_notification_login_failed: 'Falló el inicio de sesión',
              outbound_call_hung_up: 'Se ha llamado al cliente',
              phone_contact_blacklisted: 'Número de contacto añadido a la lista negra',
            },
            EVENT_DESCRIPTION: {
              payment_order_created: 'ha iniciado un intento de pago',
              payment_order_canceled: 'ha cancelado el intento de pago',
              payment_order_captured: 'ha completado un pago',
              paylink_notification_error: 'ha encontrado un error con la notificación',
              paylink_notification_visited:
                ' ha visitado <b class="text-bluegray-700">la página de pagos</b> ',
              paylink_notification_login_visited: 'ha iniciado sesión',
              paylink_notification_login_failed: 'ha tenido un error durante el inicio de sesión',
              paylink_notification_sent: 'ha enviado una notificación desde',
              paylink_notification_received: 'ha recibido una notificación desde',
              paylink_notification_read: 'ha leído la notificación',
              feedback_sent: 'ha enviado un comentario',
              feedback_visited: 'ha visitado',
              outbound_call_hung_up: 'ha sido llamado',
              api: 'API',
              campaign: 'campaña',
              payment_agreement: 'acuerdo de pago',
              reminder: 'enlace de pago',
            },
            DOCU_NAME: {
              payment_order_created: 'Intento de pago',
              payment_order_canceled: 'Cancelación del intento de pago',
              payment_order_captured: 'Pago realizado',
              paylink_notification_error: 'Error con notificación',
              paylink_notification_visited: ' Ha visitado página de pagos ',
              paylink_notification_login_visited: ' Ha hecho log in',
              paylink_notification_login_failed: ' Ha tenido un error en el log in',
              paylink_notification_sent: 'Se ha enviado una notificación',
              paylink_notification_received: 'Ha recibido notificación',
              paylink_notification_read: 'Ha leído la notificación',
              feedback_sent: 'Ha alegado',
              feedback_visited: 'Ha visitado feedback',
              outbound_call_hung_up: 'Ha sido llamado',
              api: 'API',
              campaign: 'campaña',
              payment_agreement: 'Acuerdo de pago',
              reminder: 'Enlace de pago',
            },
            PDF: {
              DOWNLOADING: 'Descargando archivo',
              PROCESSING: 'El archivo está siendo procesado, por favor espere un momento',
              CUSTOMER_DATA: 'Datos del Cliente',
              PENDING_AMOUNT: 'Importe pendiente',
              ORIGINAL_AMOUNT: 'Importe original',
              AVERAGE_PAYMENT_TIME: 'Tiempo medio de pago',
              CONTACTABILITY: 'Contactabilidad',
              CUSTOMER_DETAILS: 'Detalles del Cliente',
              NAME: 'Nombre',
              EMAIL: 'Correo electrónico',
              PHONES: 'Teléfonos',
              NIF: 'NIF',
              LOCATION: 'Ubicación',
              NO_DATA: 'No hay datos',
              DAYS: 'días',
              CLIENT_PAYMENTS: 'Pagos del cliente',
              METHOD: 'Método',
              DATE: 'Fecha',
              TOTAL_AMOUNT: 'Importe total',
              OPERATIONS: 'Operaciones',
              OPERATION_ID: 'ID de operación',
              ATTRIBUTED_AMOUNT: 'Importe atribuido',
              TITLE: 'Título',
              STATUS: 'Estado',
              DUE_DATE: 'Fecha de vencimiento',
              COLLECTOR_PROFILE: 'Perfil de cobro',
              DESCRIPTION: 'Descripción',
              NO_PAYMENTS_FOR_CLIENT: 'No existen pagos para este cliente',
              CLIENT_CASES: 'Expedientes del cliente',
              CASE_STATUS: 'Estado',
              OPERATION_AMOUNT: 'Importe',
              OPERATION_STATUS: 'Estado',
              OPERATION_TITLE: 'Título',
              OPERATION_DUE_DATE: 'Fecha de vencimiento',
              NO_CASES_FOR_CLIENT: 'No existen expedientes para este cliente',
              CLIENT_ACTIVITIES: 'Actividades del cliente',
              CATEGORY: 'Categoría',
              EVENT: 'Evento',
              EVENT_DATE: 'Fecha',
              MESSAGE_SUBJECT: 'Asunto',
              MESSAGE_TEMPLATE: 'Plantilla',
              MESSAGE_PREHEADER: 'PreHeader',
              MESSAGE_TEXT: 'Mensaje',
              NOTIFICATION_CHANNEL: 'Canal de notificación',
              NO_ACTIVITIES_FOR_CLIENT: 'No existen actividades para este cliente',
            },
            TYPE: {
              NOTIFICATION_AND_VISIT: 'Notificaciones y visitas',
              FEEDBACK: 'Comentarios',
              PAYMENT_ORDER: 'Pago',
            },
            TOAST: {
              PDF: 'PDF generado exitosamente',
              PDF_ERROR: 'Error en la generación del PDF',
            },
            TOOLTIP: {
              DOWNLOAD_PDF: 'Descargar PDF',
            },
          },
        },
      },
      OPERATIONS: {
        TITLE: 'Operaciones',
        ELEMENTS: {},
        COMPONENTS: {
          PAYMENTS: {
            TITLE: 'Pagos',
            ON_DATE: 'el',
            PAID_AMOUNT: 'ha pagado',
            PAID_PARTIAL: 'ha pagado',
            ATTRIBUTED_AMOUNT: 'de los cuales',
            VIA: 'a través',
            CUSTOM_CONCEPT: 'Concepto personalizado',
            ORDER_NUMBER: 'Número de pedido',
            VIEW_OPERATIONS: 'Ver Operaciones',
            NO_PAYMENTS: 'No hay pagos registrados en esta operación',
          },
          CONCEPT_CARD: {
            ADD_CONCEPT_HEADER: 'Añadir concepto',
            EDIT_CONCEPT_HEADER: 'Editar concepto',
            DELETE_CONCEPT_HEADER: 'Eliminar concepto',
            DELETE_CONFIRMATION_MESSAGE: '¿Seguro deseas eliminar el concepto {{concept}}?',
            DELETE_ACCEPT_LABEL: 'Eliminar',
            DELETE_REJECT_LABEL: 'No',
            DELETE_ICON: 'pi pi-exclamation-triangle',
            OPERATION_UPDATED: 'Operación {{operationTitle}} Actualizada',
            ERROR_GENERIC: 'Ocurrió un error inesperado',
            ERROR: 'Error',
            TOTAL_LABEL: 'Total',
            CURRENCY_MODAL_HEADER: 'Concepto de moneda',
            AMOUNT: 'Cantidad',
            CONCEPT: 'Concepto',
            ADD_CONCEPT_BUTTON_LABEL: 'Añadir concepto',
            CONCEPTS_TITLE: 'Conceptos',
            TOTAL_AMOUNT: 'Total',
            CHANGE_CONCEPT_BUTTON_TOOLTIP: 'Editar concepto',
            DELETE_CONCEPT_BUTTON_TOOLTIP: 'Eliminar concepto',
            CAN_WRITE_ERROR_MESSAGE: 'No puedes editar esta operación',
          },
          GENERAL_INFO: {
            ID_OPERATION_LABEL: 'ID de operación',
            COLLECTION_PROFILE_LABEL: 'Perfil de cobros',
            TITLE_LABEL: 'Título',
            WALLET_LABEL: 'Cartera',
            EXPIRATION_DATE_LABEL: 'Fecha de vencimiento',
            DESCRIPTION_LABEL: 'Descripción',
            CREATION_DATE_LABEL: 'Fecha de creación',
            MODIFICATION_DATE_LABEL: 'Fecha de modificación',
            ERROR_UPDATE_OPERATION: 'Error al actualizar la operación',
            ERROR_UPDATE_OPERATION_MESSAGE: 'Error al actualizar la operación',
            EDIT: 'Editar',
          },
          SECTIONS: {
            CONCEPTS: 'Conceptos',
            PAYMENTS: 'Pagos',
            HISTORIC: 'Histórico',
            NO_NOTES_MESSAGE: 'No hay notas para esta operación',
            NOTE_DATE_LABEL: 'Fecha:',
            WRITE_NOTE_PLACEHOLDER: 'Escribe una nota',
            SAVE_BUTTON_LABEL: 'Guardar',
            CHANGE_STATUS_BUTTON_LABEL: 'Cambiar estado',
            EXTERNAL_ID_LABEL: 'ID Ext.',
            COPY_ID_TOOLTIP: 'Copiar ID',
            GENERAL_INFORMATION_TAB: 'Información general',
            MORE_INFORMATION_TAB: 'Más información',
            NOTES_TAB: 'Notas',
            EDIT_GENERAL_INFO_TOOLTIP: 'Editar información general',
            EDIT_STATUS_HEADER: 'Editar el estado de {{operationTitle}} con ID {{operationId}}',
            EDIT_GENERAL_INFO_HEADER: 'Editar la información de la operación',
            OPERATION_UPDATED_SUCCESS: 'La operación ha sido actualizada exitosamente',
            OPERATION_STATUS_CHANGED_SUCCESS:
              'El estado de la operación ha sido cambiado exitosamente',
            OPERATION_STATUS_CHANGED_ERROR: 'Hubo un error al cambiar el estado de la operación',
            OPERATION_CHARGED_SUCCESS: 'El estado de la operación ha sido cambiado a Cargado',
            OPERATION_CHARGED_HEADER: 'Estado de operación modificado',
            ERROR_UNEXPECTED: 'Ocurrió un error inesperado. {errorMessage}',
            ERROR_GENERIC: 'Por favor, intente de nuevo más tarde.',
            COPY_TEXT_SUCCESS: 'Este texto ha sido copiado',
            COPY_TEXT_HEADER: 'Copiado',
            ACTION_PERFORMED_SUCCESS: 'Acción realizada',
            ERROR_TITLE: 'Error',
            COLLECTION_PROFILE: 'Perfil de cobro',
            PORTFOLIO: 'Cartera',
            DESCRIPTION: 'Descripción',
            CREATION_DATE: 'Fecha de creación',
            MODIFICATION_DATE: 'Fecha de modificación',
            DUE_DATE: 'Fecha de vencimiento',
            KEY_LABEL: 'Clave',
            TYPE_LABEL: 'Tipo',
            VALUE_LABEL: 'Valor',
            NO_DATA_MESSAGE: 'No hay datos para mostrar',
          },
          HISTORIC: {
            TITLE: 'Histórico',
            LEGEND: 'Legend',
            NO_HISTORIC: 'No hay historial para mostrar en esta operación',
            LEGENDS: {
              OPERATION_CREATED: 'Operación creada',
              OPERATION_STATUS_CHANGED: 'Estado de operación cambiado',
              OPERATION_PORTFOLIO_ASSIGNED: 'Portfolio asignado a la operación',
              OPERATION_UPDATED: 'Operación actualizada',
              PAYMENT_CREATED: 'Pago realizado',
              PAYMENT_ORDER_CANCELED: 'Pago cancelado',
              PAYMENT_ORDER_REFUND_SUCCEEDED: 'Reembolso de pago exitoso',
              PAYLINK_NOTIFICATION_SENT: 'Notificación enviada',
              PAYLINK_NOTIFICATION_VISITED: 'Notificación visitada',
              PAYLINK_NOTIFICATION_READ: 'Notificación leída',
              PAYLINK_NOTIFICATION_RECEIVED: 'Notificación recibida',
              PAYLINK_NOTIFICATION_ERROR: 'Error en la notificación',
              PAYLINK_NOTIFICATION_ATTACHMENT_VISITED: 'Documento leído',
              FEEDBACK_SENT: 'Feedback enviado',
              PAYMENT_ORDER_CAPTURED: 'Pago capturado',
            },
            INPUT: {
              FEEDBACK: 'Feedback',
              PAYMENT_ORDER: 'Página de pago',
              PAYMENT: 'Pago',
              PAYLINK_NOTIFICATION: 'Notificación',
              RECEIPT: 'Recibo',
              OPERATION: 'Operación',
              ACTIVITY_TYPE: 'Tipo de actividad',
            },
            ACTIVITIES: {
              PAYMENT_ORDER_CANCELED: 'ha cancelado un intento de pago',
              PAYMENT_ORDER_REFUND_SUCCEEDED: 'Ha reembolsado',
              OPERATION_CREATED: 'Operación creada',
              OPERATION_STATUS_CHANGED: 'Estado cambiado',
              NO_COMMENTS: 'Sin comentarios',
              OPERATION_UPDATED: 'Operación actualizada',
              NOTE_ADDED: 'Nota añadida',
              TITLE_UPDATED: 'Título actualizado',
              DUE_DATE_UPDATED: 'Fecha de vencimiento actualizada',
              PORTFOLIO_ASSIGNED: 'El portfolio <b>{{alias}}</b> ha sido asignado a la operación',
              UPDATED_ITEMS: 'Conceptos actualizados ',
              PAGO_REGISTRADO_DE: 'Pago registrado desde',
              POR: 'por',
              HA_ALEGADO: 'ha reclamado',
              SIN_COMENTARIO: 'Sin comentario',
              EVENT_NAME: 'Notificación del evento: {{eventName}}',
              ATTACHMENT_VISITED: 'Adjunto visitado',
              NOTIFICATION_ERROR: 'Error en la notificación',
              NOTIFICATION_RECEIVED: 'Notificación recibida',
              EXPIRATION: 'Fecha de vencimiento',
              FROM: 'De',
              SUBJECT: 'Asunto',
              PRE_HEADER: 'Pre encabezado',
              MESSAGE: 'Mensaje',
              READ: 'Léido',
              RECEIVED: 'Recibido',
              SENT: 'Enviado',
              SENT_TO: 'Enviado a',
              NUMBER_ORDER: 'Número de pedido:',
              GATEWAY_NUMBER: 'Pasarela de pago:',
              REFUND: 'ha devuelto',
              HAS_READ: 'ha leído el documento adjunto',
              ERROR_NOTIFICATION: 'Hubo un error con la notificación',
              RECIVIED_NOTIFICATION: 'ha recibido una notificación',
              VALID_UNTIL: 'Válido hasta:',
              NOTIFICATION: 'Notificación',
              TEMPLATE_NOT_FOUND: 'Plantilla no encontrada',
              ASK_PAYMENT: 'ha solicitado un recibo de pago',
              EVENT_NAMES: {
                payment_order_created: 'ha iniciado un intento de pago',
                payment_order_canceled: 'ha cancelado el intento de pago',
                payment_order_captured: 'ha pagado',
                paylink_notification_error: 'Error en la notificación',
                paylink_notification_visited:
                  'ha visitado la página de pagos, desde la notificación',
                paylink_notification_login_visited: 'Inicio de sesión',
                paylink_notification_login_failed: 'Error en el inicio de sesión',
                paylink_notification_sent: 'Notificación enviada',
                paylink_notification_received: 'ha recibido una notificación',
                paylink_notification_read: 'Notificación leída',
                feedback_sent: 'ha enviado feedback',
                feedback_visited: 'ha visitado la página de feedback',
                api: 'API',
                campaign: 'Campaña',
                payment_agreement: 'Acuerdo de pago',
                reminder: 'Recordatorio',
              },
              LABELS: {
                causation: 'Desde',
                validUntil: 'Válido hasta',
                notification: 'Notificación',
                sentTo: 'enviado a',
                copy: {
                  subject: 'Asunto',
                  message: 'Mensaje',
                },
              },
            },
          },

          STATUS_CHANGE: {
            FORM: {
              LABEL: 'Cambiar estado',
              STATUS: 'Estado',
              MESSAGE: 'Mensaje',
              PAYMENT_DATE: 'Fecha de Pago',
              PAYMENT_METHOD: 'Método de Pago',
              PAYER: 'Pagador',
            },
          },
        },
        PAGES: {
          SEARCH: {
            COLLECTOR: 'Perfil de Cobro',
            STATUS: 'Estado',
            PAYMENT_METHOD: 'Método de Pago',
            DUE_DATE: 'Fecha de Vencimiento',
            PAYMENT_DATE: 'Fecha de Pago',
            CREATION_DATE: 'Fecha de Creación',
            MODIFICATION_DATE: 'Fecha de Modificación',
            SEARCH: {
              LABEL: 'Búsqueda',
              TOOLTIP:
                'Busca entre ID DE OPERACIÓN, TÍTULO, ID DE CLIENTE, CLIENTE para resultados similares.\n\nTambién puedes buscar varios elementos separados por comas.\n\nEjemplo: Factura 22/0001, Factura 22/0002, María García',
            },
          },
          LIST: {
            TITLE: 'Listado de operaciones',
          },
          OPERATION_LIST: {
            TITLE: 'Listado de operaciones',
            NEW: 'Nuevo operaciones',
            EDIT: 'Editar operaciones',
            TOOLTIP_ALL_SELECTED: "Operations with the status 'Collected' will not be selected",
            FORM: {},
            DELETE_OPERATION_DIALOG: {
              SINGLE_HEADER: 'Eliminar operación: <b>{{ title }} ({{ externalId }})</b>',
              MULTIPLE_HEADER: 'Eliminar <b>{{ count }}</b> operaciones',
              SINGLE_CONFIRM: '¿Estás seguro de que deseas eliminar la operación {{ externalId }}?',
              MULTIPLE_CONFIRM: '¿Estás seguro de que deseas eliminar {{ count }} operaciones?',
              SUCCESS: {
                SINGLE: '{{ title }} eliminada',
                MULTIPLE: '{{ count }} operaciones eliminadas',
              },
              ERROR: {
                SINGLE: 'Hubo un error al eliminar {{ title }}',
                MULTIPLE: 'Hubo un error al eliminar {{ count }} operaciones',
              },
            },
            CHANGE_STATUS_DIALOG: {
              SINGLE_HEADER: 'Editar operación: <b>{{ title }} ({{ externalId }})</b>',
              MULTIPLE_HEADER: 'Editar operación: <b>{{ count }}</b> operaciones',
              TOAST: {
                ERROR: 'Ocurrió un error al cambiar el estado de las operaciones. {{ error }}',
                PAYMENT_SUCCESS: 'El pago se ha creado correctamente',
                STATUS_CHANGED_SUCCESS:
                  '{{ count }} operaciones cambiaron su estado a {{ status }}',
                STATUS_CHANGED_FAILED:
                  'Ocurrió un error al cambiar el estado de {{ count }} operaciones',
              },
              UNEXPECTED_ERROR: {
                SUMMARY: 'Error inesperado',
                DETAIL: 'Ocurrió un error inesperado. {{ error }}',
              },
            },
            TABLE: {
              DELETE_OP: 'Eliminar {{selectedOperations}}',
              CHANGE_STATUS_OP: 'Cambiar estado {{selectedOperations}}',
              DOWNLOAD: 'Descargar',
              DOWNLOAD_PROCESS: 'Descargar {{process}} %',
              HEADERS: {
                STATUS: 'ESTADO',
                COLLECTOR: 'PERFIL DE COBRO',
                OPERATION_ID: 'ID OPERACIÓN',
                TITLE: 'TÍTULO',
                DUE_DATE: 'VENCIMIENTO',
                CUSTOMER_ID: 'ID CLIENTE',
                CUSTOMER_NAME: 'NOMBRE CLIENTE',
                TOTAL_AMOUNT: 'IMP. TOTAL',
                PENDING_AMOUNT: 'IMP. PENDIENTE',
                PAYMENT_DATE: 'FECHA DE PAGO',
                PAYMENT_METHOD: 'MÉTODO DE PAGO',
                CREATION_DATE: 'FECHA CREACIÓN',
                UPDATE_DATE: 'FECHA ACTUALIZACIÓN',
                ACTIONS: 'ACCIONES',
              },
              MORE: {
                NO_RESULTS: 'No hay operaciones que mostrar',
              },
            },
          },
          PENDING_AMOUNT: 'Importe Pendiente',
          PENDING_AMOUNT_TOOLTIP: 'Importe pendiente de la factura',
          NO_PENDING_PAYMENTS: '✔ Sin pagos pendientes',
          ORIGINAL_AMOUNT: 'Importe Original',
          ORIGINAL_AMOUNT_TOOLTIP: 'Importe total de la operación',
          DUE_DATE: 'Fecha de Vencimiento',
          DUE_DATE_TOOLTIP: 'Fecha de vencimiento de la operación',
          CREATION_DATE: 'Fecha de Creación',
          CREATION_DATE_TOOLTIP: 'Fecha de creación de la operación',
          OPERATIONS_FILE: {
            TITLE: 'Detalles operación',
          },
        },
      },
      IMPORT: {
        TITLE: 'Listado de importaciones',
        ELEMENTS: {},
        PAGES: {
          UPLOAD: {
            TITLE: 'Importar fichero',
          },
          LIST: {
            TITLE: 'Listado de importaciones',
          },
          IMPORT_LIST: {
            TITLE: 'Listado de importaciones',
            NEW: 'Nueva importación',
            EDIT: 'Editar importación',
            ELEMENTS: {
              IMPORT: 'Importar',
            },
            TABLE: {
              HEADERS: {
                CHARGE_DATE: 'FECHA DE CARGA',
                STATUS: 'ESTADO',
                TOTAL_AMOUNT: 'IMPORTE TOTAL',
                CHARGED_AMOUNT: 'IMPORTE CARGADO',
                OP_NUMBER: 'Nº DE OPERACIONES',
                CHARGED_OP_NUMBER: 'Nº DE OPERACIONES CARGADAS',
                FILE_NAME: 'NOMBRE DE ARCHIVO',
                ACTIONS: 'ACCIONES',
                ERRORS: 'ERRORES',
              },
              VALUES: {
                ERRORS_STRING: '<b>"{{errorMessage}}"</b> en la columna <b>"{{errorColumn}}"</b>',
                NO_ERRORS: 'Todos los registros son correctos',
                NO_ERROR: 'No hay errores',
              },
              MORE: {
                NO_RESULTS: 'No hay importaciones que mostrar',
              },
            },
          },
          EDIT: {
            TITLE: 'Revisa el formato',
            FILTER: {
              TITLE: 'Filtrar formato',
              ALL: 'Todos',
              VALID: 'Correctos',
              INVALID: 'Incorrectos',
            },
            TABLE: {
              HEADERS: {
                STATUS: 'FORMATO',
                NOMBRE_CORTO: 'NOMBRE CORTO',
                NOMBRE_COMPLETO: 'NOMBRE COMPLETO',
                MOVIL: 'MÓVIL',
                EMAIL: 'CORREO',
                ES_EMPRESA: 'ES EMPRESA',
                NIF: 'NIF',
                CODIGO_CLIENTE: 'CÓDIGO CLIENTE',
                CODIGO_POSTAL: 'CÓDIGO POSTAL',
                TITULO: 'TÍTULO',
                CONCEPTO: 'CONCEPTO',
                IMPORTE: 'IMPORTE',
                CODIGO_OPERACION: 'CÓDIGO OPERACIÓN',
                CODIGO_TRANSACCION: 'CÓDIGO TRANSACCIÓN',
                FECHA_LIMITE: 'FECHA LÍMITE',
                CODIGO_IMPAGO_DEVOLUCION_SEPA: 'CÓDIGO IMPAGO',
                IDIOMA: 'IDIOMA',
                DIRECCION: 'DIRECCIÓN',
                CIUDAD: 'CIUDAD',
                PAIS: 'PAÍS',
                ALIAS: 'ALIAS',
                URL_FACTURA: 'URL FACTURA',
                ESTADO: 'ESTADO',
              },
              CELL: {
                STATUS: {
                  VALID: 'Correcto',
                  INVALID: 'Error',
                },
              },
            },
            MORE: {
              NO_RESULTS: 'No hay líneas que mostrar',
              TIME_LIMIT:
                'Las líneas importadas tendrán una vigencia de 5 días, tras los cuales la información será eliminada.',
              LOADING_RESULTS: 'Cargando líneas...',
            },
          },
          END: {
            TITLE: 'Finalizar importación',
            DESCRIPTION: {
              TITLE: 'Resumen',
              OPERATIONS_NUMBER_TITLE: 'Número de operaciones a importar:',
              OPERATIONS_NUMBER: '{{operations}} de {{operations_total}}',
              TOTAL_IMPORT: 'Importe total: ',
            },
          },
        },
      },
      SEGMENTS: {
        TITLE: 'Segmentos',
        ELEMENTS: {},
        PAGES: {
          SEGMENT_LIST: {
            TITLE: 'Listado de segmentos',
            NEW: 'Nuevo segmento',
            EDIT: 'Editar segmento',
            INFO_SEGMENTS: 'Información de segments',
            CREATE_NEW: 'Crear un nuevo segmento',
            ERROR: 'Error',
            ERROR_CREATING: 'Hubo un error al crear el segmento.',
            ERROR_UPDATING: 'Hubo un error al actualizar el segmento.',
            ERROR_DELETING: 'Hubo un error al eliminar {{name}}.',
            SUCCESS: 'Éxito',
            SEGMENT_CREATED: 'El segmento {{name}} se ha creado correctamente.',
            SEGMENT_UPDATED: 'El segmento {{name}} se ha actualizado correctamente.',
            SEGMENT_DELETED: 'El segmento {{name}} se ha eliminado correctamente.',
            DELETE: 'Eliminar Segmento',
            DELETE_CONFIRMATION: '¿Seguro que quieres eliminar a {{name}}?',
            TOOLTIP_IN_USE: 'Segmento en uso en campañas.',
            VIEW: 'Ver Segmento',
            DIALOG: `No se pueden <b>borrar</b> segmentos si ya <b>están en uso</b> en una campaña. Si quieres borrarlo, debes
            archivar la campaña que lo utiliza.`,
            FORM: {
              NAME: 'NOMBRE',
            },
            TABLE: {
              HEADERS: {
                NAME: 'NOMBRE',
                ACTIONS: 'ACCIONES',
              },
              ACTIONS: {
                EDIT: 'Editar',
                DELETE: 'Eliminar',
              },
              MORE: {
                NO_RESULTS: 'No hay segmentos que mostrar',
              },
            },
          },
        },
      },
      PAYMENT_AGREEMENT: {
        TITLE: 'Acuerdos de pago',
        ELEMENTS: {
          DOWNLOAD: {
            LABEL: 'Descargar',
          },
        },
        FORM: {
          PROFILE_OF_COLLECTION: 'Perfil de Cobro',
          STATUS: 'Estado',
          SEARCH: 'Búsqueda',
          SELECT_PROFILE: 'Seleccionar Perfil',
          SELECT_STATUS: 'Seleccionar Estado',
          ENTER_SEARCH_PATTERN: 'Ingrese el patrón de búsqueda',
          BROKEN: 'Roto',
          FULFILLED: 'Cumplido',
          EXPIRED: 'Expirado',
          PROPOSAL: 'Propuesta',
          SIGNED: 'Firmado',
        },
        PAGES: {
          LIST: {
            TABLE: {
              HEADERS: {
                COLLECTOR: 'PERFIL DE COBRO',
                CHARGE_DATE: 'FECHA DE INICIO',
                STATUS: 'ESTADO',
                TOTAL_AMOUNT: 'IMPORTE TOTAL',
                CHARGED_AMOUNT: 'IMPORTE COBRADO',
                OP_NUMBER: 'NÚMERO DE OPERACIONES',
                CHARGED_OP_NUMBER: 'CUOTAS PAGADAS',
                FILE_NAME: 'COLLECTOR',
                EXTERNAL_CLIENT_CODE: 'CÓDIGO EXTERNO CLIENTE',
                CLIENT: 'CLIENTE',
                TITLE: 'TÍTULO',
              },

              MORE: {
                NO_RESULTS: 'No hay acuerdos de pago que mostrar',
              },
            },
          },
        },
      },
      REPORTS: {
        TITLE: 'Reportes',
        BC: {
          PANEL: 'Panel',
          CLIENTS: 'Clientes',
          CAMPAIGNS: 'Campañas',
          COLLECTION: 'Recobro por periodo',
          COLLECTORS: 'Perfiles de cobro',
          WORKFLOWS: 'Recobro por mes',
          PORTFOLIOS: 'Cartera global',
        },
        FILTERS: {
          CAMPAIGNS: 'CAMPAÑAS',
          COLLECTORS: 'PERFIL DE COBRO',
          PORTFOLIOS: 'CARTERAS',
          DATE: 'FECHA',
          DATE_NOTIFICATION: 'FECHA DE NOTIFICACIÓN',
          DATE_COLLECTION_CREATED: 'MES CREACIÓN DEUDA',
          PERIOD: 'PERIODO DE RECOBRO (EN MESES)',
          CAMPAIGN_PAYMENTS: 'RECUPERACIÓN POR CAMPAÑA',
          STATUS_OPERATION_IGNORE: 'IGNORAR ESTADO OPERACIÓN',
        },
        PAGES: {
          ROOT: {
            DESCRIPTIONS: {
              PANEL: 'Informes de Tenant',
              CLIENTS: 'Informes de clientes',
              CAMPAIGNS: 'Informes de campañas',
              COLLECTORS: 'Informes de perfiles de cobro',
              COLLECTION: 'Informes de recobro por periodo',
              WORKFLOWS: 'Informes de recobro por mes',
              PORTFOLIOS: 'Informes de cartera global',
            },
          },
        },
      },
      PAYMENT_METHOD: {
        FORM: {
          INPUT: {
            PAYMENT_METHOD: 'Método de pago',
          },
        },
        ADD: {
          TITLE: 'Nuevo método de pago',
          BUTTON: {
            BACK: 'Atrás',
          },
        },
        EDIT: {
          TITLE: 'Editar método de pago',
          ENABLED: 'Habilitado',
          BUTTON: {
            BACK: 'Atrás',
          },
        },
        LIST: {
          TITLE: 'Métodos de pago',
          BUTTON: {
            ADD: 'Nuevo método de pago',
          },
          INPUT: {
            SEARCH: 'Buscar',
          },
          TABLE: {
            HEADER: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              TYPE: 'Tipo',
              STATUS: 'Estado',
              ACTIONS: 'Acciones',
              COLLECTORS: 'Collectors asociados',
            },
            VALUES: {
              ENABLED: 'Activo',
              DISABLED: 'Inactivo',
            },
            BUTTON: {
              EDIT: 'Editar',
              DELETE: 'Eliminar',
            },
          },
        },
        REDSYS: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              TYPE: 'Tipo',
              STATUS: 'Estado',
              ACTIONS: 'Acciones',
              MERCHANT_CODE: 'Merchant code',
              MERCHANT_TERMINAL_ID: 'Merchant terminal id',
              MERCHANT_SECRET: 'Merchant secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `
                <section>
                <p>Detalles Adicionales sobre Conceptos Personalizados de Pago</p>
                <p>Al configurar tus conceptos personalizados de pago, ten en cuenta lo siguiente:</p>
                <ul>
                    <li>Si utilizas la variable opcional <code>$PERSONALIZADO$</code>, debes asegurarte de incluir esta información en Paymefy. De lo contrario, la información no se imprimirá en el concepto.</li>
                    <li>En caso de multifactura, todos los códigos aparecerán separados por comas (<code>,</code>).</li>
                    <li>Concepto por defecto: <strong>título</strong>.</li>
                    <li>Límite de caracteres: <strong>125</strong>. Si se supera, el concepto será recortado.</li>
                    <li>Caracteres permitidos:
                        <ul>
                            <li>Caracteres de la <code>a</code> a la <code>z</code> (asegúrate de no incluir caracteres especiales o mayúsculas).</li>
                            <li>Números del <code>0</code> al <code>9</code>.</li>
                        </ul>
                    </li>
                </ul>
            </section>`,
                EXTRA: {
                  BIZUM: 'Bizum (Redsys)',
                },
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        INESPAY: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              COMPANY_TYPE: 'Tipo',
              ACTIONS: 'Acciones',
              IBAN: 'IBAN',
              API_KEY: 'API KEY',
              API_TOKEN: 'API TOKEN',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `
                <section>
        <p>Información Actualizada sobre Conceptos Personalizados de Pago</p>
        <p>Considera las siguientes directrices al configurar tus conceptos personalizados de pago en Paymefy:</p>
        <ul>
            <li>Si utilizas <strong>variables opcionales</strong>, debes asegurarte de incluir esta información en Paymefy. De lo contrario, la información no se imprimirá en el concepto.</li>
            <li>Concepto por defecto: <em>iban de cuenta origen, external_id, nombre largo</em>.</li>
            <li>En caso de multifactura, todos los códigos aparecen separados por comas (<code>,</code>).</li>
            <li>Límite de caracteres: <strong>100</strong>. Si se supera, el concepto será recortado.</li>
            <li>Caracteres permitidos:
                <ul>
                    <li>Caracteres de la <code>a</code> a la <code>z</code>.</li>
                    <li>Números del <code>0</code> al <code>9</code>.</li>
                    <li>Otros caracteres permitidos: <code>+</code>, <code>?</code>, <code>/</code>, <code>-</code>, <code>:</code>, <code>(</code>, <code>)</code>, <code>.</code>, <code>,</code>, <code>'</code>.</li>
                </ul>
            </li>
        </ul>
    </section>
                `,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        STRIPE: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              COMPANY_TYPE: 'Tipo',
              ACTIONS: 'Acciones',
              SECRET_KEY: 'Secret key',
              PUBLISHABLE_KEY: 'Publishable key',
              WEB_HOOK_SECRET: 'Web hook secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `<section>
                <p>Información sobre Conceptos Personalizados de Pago</p>
                <p>Los conceptos personalizados de pago deben cumplir con las siguientes normativas:</p>
                <ul>
                    <li>Deben contener <strong>solo caracteres latinos</strong>.</li>
                    <li>La longitud debe ser de <strong>entre 5 y 22 caracteres</strong>, incluyendo ambos extremos.</li>
                    <li>Deben contener <strong>al menos una letra</strong>. Si se usa un prefijo y un sufijo, ambos deben contener al menos una letra.</li>
                    <li><strong>No deben contener</strong> ninguno de los siguientes caracteres especiales: <code>&lt;</code>, <code>&gt;</code>, <code>V</code>, <code>*</code>, <code>"</code>.</li>
                </ul>
            </section>`,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        PAYCOMET: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              COMPANY_TYPE: 'Tipo',
              ACTIONS: 'Acciones',
              IBAN: 'IBAN',
              SWIFT_CODE: 'Código SWIFT',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `<section>
                <p>Instrucciones para Conceptos Personalizados de Pago</p>
                <p>Al configurar conceptos personalizados de pago en Paymefy, ten en cuenta las siguientes directrices:</p>
                <ul>
                    <li>Para el uso de <strong>variables opcionales</strong>, es necesario incluir esta información en Paymefy. Si no se incluye, la información correspondiente no se mostrará en el concepto de pago.</li>
                    <li>El <strong>concepto por defecto</strong> se compone de: iban de cuenta origen, external_id, nombre largo.</li>
                    <li>En situaciones de <strong>multifactura</strong>, todos los códigos se listarán separados por comas (<code>,</code>).</li>
                    <li>Existe un <strong>límite de caracteres de 100</strong>. Si el concepto excede este límite, será necesario recortarlo.</li>
                    <li>Los <strong>caracteres permitidos</strong> incluyen:
                        <ul>
                            <li>Caracteres alfabéticos de la <code>a</code> a la <code>z</code>.</li>
                            <li>Números del <code>0</code> al <code>9</code>.</li>
                            <li>Además, se permiten los siguientes caracteres especiales: <code>+</code>, <code>?</code>, <code>/</code>, <code>-</code>, <code>:</code>, <code>(</code>, <code>)</code>, <code>.</code>, <code>,</code>, <code>'</code>.</li>
                        </ul>
                    </li>
                </ul>
            </section>`,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        INGENICO: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              PSPID: 'Pspid',
              INTEGRATOR: 'Integrator',
              API_KEY: 'Api key',
              API_SECRET: 'Api secret',
              WEBHOOKS_KEY: 'Webhooks key',
              WEBHOOKS_KEY_SECRET: 'Webhooks key secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: ``,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        MYMOID: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              APP_ID: 'App id',
              APP_SECRET: 'App secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: `
                <section>
                <p>Configuración de Conceptos Personalizados de Pago</p>
                <p>Al definir conceptos personalizados de pago para su uso en Paymefy, considera las siguientes directrices:</p>
                <ul>
                    <li>Al usar la variable opcional <code>$PERSONALIZADO$</code>, es crucial asegurarse de que esta información se haya ingresado en Paymefy; de lo contrario, no aparecerá en el concepto.</li>
                    <li>Para casos de <strong>multifactura</strong>, todos los códigos se mostrarán separados por comas (<code>,</code>).</li>
                    <li><strong>Concepto por defecto:</strong> No existe un concepto por defecto.</li>
                    <li>El <strong>límite de caracteres</strong> es de <strong>150</strong>. Si se supera este límite, el concepto será recortado.</li>
                    <li><strong>Mymoid</strong> no impone restricciones sobre los caracteres utilizados en los conceptos de pago.</li>
                </ul>
            </section>
        </body>`,
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        MONETICO: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              SOCIETE: 'Nombre empresa',
              TPE: 'Nº TPE',
              KEY: 'KEY',
              DEFAULT_ADDRESS: 'Dirección',
              DEFAULT_CITY: 'Ciudad',
              DEFAULT_POSTAL_CODE: 'Código postal',
              DEFAULT_COUNTRY: 'Código de país',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: '',
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        MONEI: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              TYPE: 'Tipo',
              STATUS: 'Estado',
              ACTIONS: 'Acciones',
              MERCHANT_CODE: 'Merchant code',
              MERCHANT_TERMINAL_ID: 'Merchant terminal id',
              MERCHANT_SECRET: 'Merchant secret',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
        GREENPAY: {
          FORM: {
            INPUT: {
              NAME: 'Nombre',
              DESCRIPTION: 'Descripción',
              API_KEY: 'API KEY',
              ACCOUNT_ID: 'ACCOUNT ID',
              CUSTOM_CONCEPT: 'Concepto personalizado',
              TEXT: {
                MESSAGE:
                  'Utiliza estas etiquetas en el "Texto a mostrar" para personalizar tus mensajes:',
                LABELS: {
                  NAME: 'Nombre largo',
                  CLIENT_ID: 'ID del cliente',
                  NIF: 'NIF',
                  TITLE: 'Título',
                  OPERATION_ID: 'ID operación',
                  PERSONALIZADO: '$PERSONALIZADO$',
                },
                INFO: '',
              },
            },
            BUTTON: {
              CANCEL: 'Cancelar',
              SAVE: 'Guardar',
            },
          },
        },
      },
    },
  },
};
