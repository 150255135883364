<!--
    V.1.0+
    <p-button
    [disabled]="!showReminder()"
    [style]="{'width':'100%'}"
    [label]="'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.TITLE' | translate"
    (onClick)="initSender()"
/> -->

<!-- V.2.0 -->
<div *ngIf="isButton; else noButton">
   <p-button
      (onClick)="initSender()"
      [disabled]="!showReminder()"
      icon="pi pi-bell"
      [rounded]="true"
      severity="secondary"
      tooltipPosition="left"
      [pTooltip]="'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.TITLE' | translate"
   />
</div>
<ng-template #noButton>
    <p
        class="flex flex-row gap-2 px-3 py-2 text-700 text-small align-items-center no-button"
        (click)="showReminder() ? initSender() : null"
        [ngClass]="{ 'disabled': !showReminder() }"
    >
        <i class="pi pi-bell"></i>
        {{ 'DATA.MODULES.SHARED.COMPONENTS.SEND_REMINDER.TITLE' | translate }}
    </p>
</ng-template>
