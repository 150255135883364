<ng-container [formGroup]="form">
   <ng-container [ngSwitch]="field.controlType">
      <!-- PRIME INPUT START -->
      <ng-container *ngSwitchCase="'textbox'">
         <div *ngIf="field.isVisible">
            <div class="flex justify-content-start align-items-center">
               <label>
                  {{ field.label | translate }}
                  <span style="color: red" *ngIf="field.required || field.falseRequired">*</span>
               </label>
               <i
                  *ngIf="field.infoVisibility"
                  class="pi pi-info-circle ml-2"
                  style="font-size: 1rem"
                  icon="pi pi-info-circle"
                  pTooltip="{{ field.infotooltip || '' | translate }}"
                  tooltipPosition="{{ field.tooltipPosition }}"
               ></i>
            </div>
            <div class="flex align-items-center justify-content-start">
               <input
                  *ngIf="!field.inputGroup"
                  (keydown.enter)="sendEnter($event)"
                  (keydown.delete)="sendSupr($event)"
                  [required]="field.required"
                  pInputText
                  autocomplete="off"
                  [formControlName]="field.key"
                  [id]="field.key"
                  [type]="field.type"
                  [class]="getFormControlClasses()"
                  [readonly]="field.readonly"
                  placeholder="{{ field.placeholder | translate }}"
                  [readonly]="field.disabled"
               />

               <div class="p-inputgroup" *ngIf="field.inputGroup">
                  <input
                     (keydown.enter)="sendEnter($event)"
                     (keydown.delete)="sendSupr($event)"
                     [required]="field.required"
                     pInputText
                     autocomplete="off"
                     [formControlName]="field.key"
                     [id]="field.key"
                     [type]="field.type"
                     [class]="getFormControlClasses()"
                     [readonly]="field.readonly"
                     placeholder="{{ field.placeholder | translate }}"
                     [readonly]="field.disabled"
                  />
                  <p-inputGroupAddon>{{ field.addon | translate }}</p-inputGroupAddon>
               </div>
            </div>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME INPUT END -->

      <!-- PRIME INPUT NUMBER START -->
      <ng-container *ngSwitchCase="'number'">
         <div *ngIf="field.isVisible">
            <div class="flex justify-content-start align-items-center">
               <label>
                  {{ field.label | translate }}
                  <span style="color: red" *ngIf="field.required || field.falseRequired">*</span>
               </label>
               <i
                  *ngIf="field.infoVisibility"
                  class="pi pi-info-circle ml-2"
                  style="font-size: 1rem"
                  icon="pi pi-info-circle"
                  pTooltip="{{ field.infotooltip || '' | translate }}"
                  tooltipPosition="{{ field.tooltipPosition }}"
               ></i>
            </div>
            <div class="flex align-items-center justify-content-start">
               <p-inputNumber
                  *ngIf="!field.inputGroup"
                  (keydown.enter)="sendEnter($event)"
                  (keydown.delete)="sendSupr($event)"
                  (onInput)="
                     form
                        .get(field.key)
                        ?.patchValue(
                           Number($event.value) >= 0 && Number($event.value) <= safeMaxNumber
                              ? $event.value
                              : field.value
                        )
                  "
                  [required]="field.required"
                  [formControlName]="field.key"
                  [id]="field.key"
                  [class]="getFormControlClasses()"
                  [readonly]="field.readonly"
                  placeholder="{{ field.placeholder | translate }}"
                  [readonly]="field.disabled"
                  [inputId]="field.key"
                  [min]="field.min!"
                  mode="decimal"
                  inputId="withoutgrouping"
                  [useGrouping]="false"
               >
               </p-inputNumber>

               <div class="p-inputgroup" *ngIf="field.inputGroup">
                  <p-inputNumber
                     (keydown.enter)="sendEnter($event)"
                     (keydown.delete)="sendSupr($event)"
                     (onInput)="
                        form
                           .get(field.key)
                           ?.patchValue(
                              Number($event.value) >= 0 && Number($event.value) <= safeMaxNumber
                                 ? Number($event.value)
                                 : field.value
                           )
                     "
                     [required]="field.required"
                     [formControlName]="field.key"
                     [id]="field.key"
                     [class]="getFormControlClasses()"
                     [readonly]="field.readonly"
                     placeholder="{{ field.placeholder | translate }}"
                     [readonly]="field.disabled"
                     [inputId]="field.key"
                     [min]="field.min!"
                     mode="decimal"
                     inputId="withoutgrouping"
                     [useGrouping]="false"
                  >
                  </p-inputNumber>

                  <p-inputGroupAddon>{{ field.addon | translate }}</p-inputGroupAddon>
               </div>
            </div>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME INPUT NUMBER END -->

      <!-- PRIME  CHIPS START -->
      <ng-container *ngSwitchCase="'chipsbox'">
         <div *ngIf="field.isVisible">
            <div class="flex justify-content-start align-items-center">
               <label>
                  {{ field.label | translate }}
                  <span style="color: red" *ngIf="field.required || field.falseRequired">*</span>
               </label>
               <i
                  *ngIf="field.infoVisibility"
                  class="pi pi-info-circle ml-2"
                  style="font-size: 1rem"
                  icon="pi pi-info-circle"
                  pTooltip="{{ field.infotooltip || '' | translate }}"
                  tooltipPosition="{{ field.tooltipPosition }}"
               ></i>
            </div>
            <div class="flex flex-row gap-2">
               <p-chips
                  (keydown.delete)="sendSupr($event)"
                  #chipRef
                  [required]="field.required"
                  [formControlName]="field.key"
                  [id]="field.key"
                  [separator]="','"
                  [addOnBlur]="true"
                  (onChipClick)="editTag($event)"
                  [allowDuplicate]="false"
                  (onRemove)="sendValueToParent()"
                  appendTo="body"
                  [class]="getFormControlClasses()"
                  placeholder="{{ field.placeholder | translate }}"
                  [disabled]="field.disabled"
                  class="w-full"
                  [ngStyle]="{
                     'max-height': '41.5px'
                  }"
               ></p-chips>
               <ng-container *ngIf="chipCount > 0">
                  <div class="flex align-items-center">
                     <p-chip [label]="chipCount.toString()"></p-chip>
                  </div>
               </ng-container>
            </div>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME  CHIPS END -->

      <!-- RADIO BUTTON -->
      <ng-container *ngSwitchCase="'radiobox'">
         <div *ngIf="field.isVisible">
            <div class="flex justify-content-start align-items-center mb-1">
               <label>
                  {{ field.label | translate }}
                  <span style="color: red" *ngIf="field.required || field.falseRequired">*</span>
               </label>
               <i
                  *ngIf="field.infoVisibility"
                  class="pi pi-info-circle ml-2"
                  style="font-size: 1rem"
                  icon="pi pi-info-circle"
                  pTooltip="{{ field.infotooltip || '' | translate }}"
                  tooltipPosition="{{ field.tooltipPosition }}"
               ></i>
            </div>
            <div class="flex flex-row gap-2">
               <div *ngFor="let option of field.options" class="flex flex-row align-items-center gap-2">
                  <input
                     type="radio"
                     [name]="field.key"
                     class="cursor-pointer"
                     [value]="option.value"
                     [formControlName]="field.key"
                     [disabled]="field.disabled"
                     (change)="sendValueToParent()"
                  />
                  <label>{{ option.label | translate }}</label>
               </div>
            </div>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>

      <!-- RADIO BUTTON END -->

      <!-- PRIME DROPDOWN THREE START -->
      <ng-container *ngSwitchCase="'dropdownThree'">
         <div *ngIf="field.isVisible">
            <div class="flex justify-content-start align-items-center">
               <label>
                  {{ field.label | translate }}
                  <span style="color: red" *ngIf="field.required || field.falseRequired">*</span>
               </label>
               <i
                  *ngIf="field.infoVisibility"
                  class="pi pi-info-circle ml-2"
                  style="font-size: 1rem"
                  icon="pi pi-info-circle"
                  pTooltip="{{ field.infotooltip || '' | translate }}"
                  tooltipPosition="{{ field.tooltipPosition }}"
               ></i>
            </div>
            <div>
               <p-treeSelect
                  appendTo="body"
                  [required]="field.required"
                  [id]="field.key"
                  [options]="field.options"
                  [formControlName]="field.key"
                  [disabled]="field.disabled"
                  [filter]="field.filter || false"
                  placeholder="{{ field.placeholder | translate }}"
                  [class]="getFormControlClasses()"
               ></p-treeSelect>
            </div>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME DROPDOWN THREE END -->

      <!-- PRIME DROPDOWN THREE START -->
      <ng-container *ngSwitchCase="'cascade'">
         <div *ngIf="field.isVisible">
            <div class="flex justify-content-start align-items-center">
               <label>
                  {{ field.label | translate }}
                  <span style="color: red" *ngIf="field.required || field.falseRequired">*</span>
               </label>
               <i
                  *ngIf="field.infoVisibility"
                  class="pi pi-info-circle ml-2"
                  style="font-size: 1rem"
                  icon="pi pi-info-circle"
                  pTooltip="{{ field.infotooltip || '' | translate }}"
                  tooltipPosition="{{ field.tooltipPosition }}"
               ></i>
            </div>
            <div>
               <p-cascadeSelect
                  appendTo="body"
                  [required]="field.required"
                  [id]="field.key"
                  optionLabel="label"
                  optionGroupLabel="label"
                  [optionGroupChildren]="['children', 'children']"
                  [options]="field.options"
                  [formControlName]="field.key"
                  [disabled]="field.disabled"
                  placeholder="{{ field.placeholder | translate }}"
                  [class]="getFormControlClasses()"
               ></p-cascadeSelect>
            </div>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME DROPDOWN THREE END -->

      <!-- PRIME TEXT AREA START -->
      <ng-container *ngSwitchCase="'textarea'">
         <div *ngIf="field.isVisible">
            <label>
               {{ field.label | translate }}
               <span style="color: red" *ngIf="field.required || field.falseRequired">*</span></label
            >
            <i
               *ngIf="field.infoVisibility"
               class="pi pi-info-circle ml-2"
               style="font-size: 1rem"
               icon="pi pi-info-circle"
               pTooltip="{{ field.infotooltip || '' | translate }}"
               tooltipPosition="{{ field.tooltipPosition }}"
            ></i>

            <textarea
               (keydown.enter)="sendEnter($event)"
               (keydown.delete)="sendSupr($event)"
               #textareaRef
               appendTo="body"
               [required]="field.required"
               [rows]="field.wideTextArea ? field.wideTextArea : 5"
               [cols]="30"
               pInputTextarea
               [autoResize]="true"
               [formControlName]="field.key"
               [id]="field.key"
               [readonly]="field.disabled"
               [class]="getFormControlClasses()"
               [readonly]="field.readonly"
               placeholder="{{ field.placeholder | translate }}"
            >
            </textarea>

            <div class="flex justify-content-between flex-wrap">
               <app-placeholders-selector
                  [placeholders]="field.customPlaceholders"
                  (placeholderSelected)="addPlaceholder($event)"
                  *ngIf="field.placeholderSelector"
               />
               <app-characters-counter
                  *ngIf="field.maxCharacters"
                  [currentChar]="field.currentCharacters || 0"
                  [maxChar]="field.maxCharacters"
                  [hasSegments]="field.hasSegments"
               />
            </div>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME TEXT AREA END -->

      <!-- PRIME SELECT START -->
      <ng-container *ngSwitchCase="'dropdown'">
         <div *ngIf="field.isVisible">
            <label>
               {{ field.label | translate }}
               <span style="color: red" *ngIf="field.required || field.falseRequired">*</span></label
            >
            <i
               *ngIf="field.infoVisibility"
               class="pi pi-info-circle ml-2"
               style="font-size: 1rem"
               icon="pi pi-info-circle"
               pTooltip="{{ field.infotooltip || '' | translate }}"
               tooltipPosition="{{ field.tooltipPosition }}"
            ></i>
            <p-multiSelect
               appendTo="body"
               *ngIf="field.multi"
               [required]="field.required"
               [placeholder]="field.placeholder || ('DATA.COMMON.SEARCH.PLACEHOLDER_DROPDOWN' | translate)"
               defaultLabel="{{ 'DATA.COMMON.SEARCH.PLACEHOLDER_DROPDOWN' | translate }}"
               display="chip"
               [options]="field.options"
               [showClear]="field.showClear"
               [formControlName]="field.key"
               [filter]="field.filter || false"
               [class]="getFormControlClasses()"
            >
            </p-multiSelect>

            <p-dropdown
               appendTo="body"
               *ngIf="!field.multi && !field.grouped"
               [required]="field.required"
               [showClear]="field.showClear"
               (onClear)="resetInput()"
               [filter]="field.filter || false"
               [placeholder]="field.placeholder || ('DATA.COMMON.SEARCH.PLACEHOLDER_DROPDOWN' | translate)"
               [class]="getFormControlClasses()"
               [options]="field.options"
               [formControlName]="field.key"
            >
            </p-dropdown>
            <p-dropdown
               *ngIf="field.grouped && !field.multi"
               appendTo="body"
               [options]="field.options"
               [required]="field.required"
               [showClear]="field.showClear"
               [filter]="field.filter || false"
               [filterBy]="'label'"
               [class]="getFormControlClasses()"
               (onClear)="resetInput()"
               [placeholder]="field.placeholder || ('DATA.COMMON.SEARCH.PLACEHOLDER_DROPDOWN' | translate)"
               [group]="true"
               [formControlName]="field.key"
            >
               <ng-template let-group pTemplate="group">
                  <div class="flex flex-column">
                     <app-custom-tag [status]="group.value.toUpperCase()" [value]="group.section" />
                     <p-divider class="w-full"></p-divider>
                  </div>
               </ng-template>
            </p-dropdown>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME SELECT END -->

      <!-- PRIME DATE START -->
      <ng-container *ngSwitchCase="'date'">
         <div *ngIf="field.isVisible">
            <label>
               {{ field.label | translate }}
               <span style="color: red" *ngIf="field.required || field.falseRequired">*</span></label
            >
            <i
               *ngIf="field.infoVisibility"
               class="pi pi-info-circle ml-2"
               style="font-size: 1rem"
               icon="pi pi-info-circle"
               pTooltip="{{ field.infotooltip || '' | translate }}"
               tooltipPosition="{{ field.tooltipPosition }}"
            ></i>
            <p-calendar
               *ngIf="!field.selectionMode"
               [class]="getFormControlClasses()"
               [required]="field.required"
               dateFormat="dd/mm/yy"
               [placeholder]="
                  field.showTime
                     ? field.placeholder || ('DATA.COMMON.FORM.PLACEHOLDER_RANGE' | translate)
                     : field.placeholder || ('DATA.COMMON.FORM.PLACEHOLDER_RANGE' | translate)
               "
               [maxDate]="field.maxDate!"
               [minDate]="field.minDate!"
               [firstDayOfWeek]="1"
               [showIcon]="true"
               [showTime]="field.showTime!"
               [view]="field.dateView || 'date'"
               [defaultDate]="defaultDatesInit"
               [formControlName]="field.key"
            >
            </p-calendar>

            <p-calendar
               *ngIf="field.selectionMode"
               [required]="field.required"
               [class]="getFormControlClasses()"
               [placeholder]="
                  field.showTime
                     ? field.placeholder || ('DATA.COMMON.FORM.PLACEHOLDER_RANGE' | translate)
                     : field.placeholder || ('DATA.COMMON.FORM.PLACEHOLDER_RANGE' | translate)
               "
               dateFormat="dd/mm/yy"
               [firstDayOfWeek]="1"
               [showTime]="field.showTime!"
               [defaultDate]="defaultDatesInit"
               [selectionMode]="'range'"
               [maxDate]="field.maxDate!"
               [minDate]="field.minDate!"
               [view]="field.dateView || 'date'"
               [showIcon]="true"
               [formControlName]="field.key"
            >
            </p-calendar>

            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME DATE END -->

      <!-- CURRENCY INPUT START-->
      <ng-container *ngSwitchCase="'currency'">
         <div *ngIf="field.isVisible">
            <label>
               {{ field.label | translate }}
               <span style="color: red" *ngIf="field.required || field.falseRequired">*</span></label
            >
            <i
               *ngIf="field.infoVisibility"
               class="pi pi-info-circle ml-2"
               style="font-size: 1rem"
               icon="pi pi-info-circle"
               pTooltip="{{ field.infotooltip || '' | translate }}"
               tooltipPosition="{{ field.tooltipPosition }}"
            ></i>

            <div class="p-inputgroup" *ngIf="field.inputGroup">
               <p-inputNumber
                  (keydown.enter)="sendEnter($event)"
                  (keydown.delete)="sendSupr($event)"
                  (onInput)="
                     form
                        .get(field.key)
                        ?.patchValue(
                           Number($event.value) >= 0 && Number($event.value) <= safeMaxNumber
                              ? Number($event.value)
                              : field.value
                        )
                  "
                  [required]="field.required"
                  [formControlName]="field.key"
                  [id]="field.key"
                  [class]="getFormControlClasses()"
                  [readonly]="field.readonly"
                  placeholder="{{ field.placeholder | translate }}"
                  [readonly]="field.disabled"
                  [inputId]="field.key"
                  mode="decimal"
                  [min]="field.min!"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="5"
               >
               </p-inputNumber>

               <p-inputGroupAddon>{{ field.currency }}</p-inputGroupAddon>
            </div>

            <p-inputNumber
               *ngIf="!field.inputGroup"
               (keydown.enter)="sendEnter($event)"
               (keydown.delete)="sendSupr($event)"
               (onInput)="
                  form
                     .get(field.key)
                     ?.patchValue(
                        Number($event.value) >= 0 && Number($event.value) <= safeMaxNumber
                           ? Number($event.value)
                           : field.value
                     )
               "
               [required]="field.required"
               [formControlName]="field.key"
               [id]="field.key"
               [class]="getFormControlClasses()"
               [readonly]="field.readonly"
               placeholder="{{ field.placeholder | translate }}"
               [readonly]="field.disabled"
               [inputId]="field.key"
               mode="decimal"
               [min]="field.min!"
               [minFractionDigits]="2"
               [maxFractionDigits]="5"
            >
            </p-inputNumber>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- CURRENCY INPUT END-->

      <!-- PRIME RANGE START -->
      <ng-container *ngSwitchCase="'range'">
         <div *ngIf="field.isVisible">
            <label>
               {{ field.label | translate }}
               <span style="color: red" *ngIf="field.required || field.falseRequired">*</span></label
            >
            <i
               *ngIf="field.infoVisibility"
               class="pi pi-info-circle ml-2"
               style="font-size: 1rem"
               icon="pi pi-info-circle"
               pTooltip="{{ field.infotooltip || '' | translate }}"
               tooltipPosition="{{ field.tooltipPosition }}"
            ></i>

            <div>
               <input
                  type="text"
                  [required]="field.required"
                  pInputTextarea
                  [formControlName]="field.key"
                  [id]="field.key"
                  [readonly]="field.disabled"
                  [class]="getFormControlClasses()"
                  [readonly]="field.readonly"
                  placeholder="{{ field.placeholder | translate }}"
                  pInputText
                  class="w-full"
               />
               <p-slider [(ngModel)]="field.value" [range]="true" class="w-full"></p-slider>
            </div>

            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- PRIME RANGE END -->

      <!-- CHECKBOX PRIME START -->
      <ng-container *ngSwitchCase="'checkbox'">
         <div *ngIf="field.isVisible" class="flex flex-column">
            <div class="flex gap-2 mt-2 align-items-center">
               <p-checkbox
                  [required]="field.required"
                  [formControlName]="field.key"
                  [id]="field.key"
                  [class]="getFormControlClasses()"
                  [readonly]="field.readonly"
                  [binary]="true"
                  [inputId]="field.key"
               ></p-checkbox>
               <label [for]="field.key">{{ field.label }}</label>
            </div>
         </div>
         <div>
            <app-error-handler *ngIf="hasFieldError()" [errors]="form.get(this.field.key)!.errors"></app-error-handler>
         </div>
      </ng-container>
      <!-- CHECKBOX PRIME END -->
   </ng-container>
</ng-container>
